import React from 'react';
import {Td, Tr} from "react-super-responsive-table";
import {Checkbox, Intent, Position, Toaster} from "@blueprintjs/core";
import Loader from "../../../../components/Loader";

export default class BeneficiaryList extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            checkLoader:false,
            removeLoader:false
        }
    }

    updateCartBeneficiary = (id) => {

        let obj = {
            id : id
        }
        this.setState({
            checkLoader:true
        })
        this.props.setButtonStatus(false);
        this.props.updateCartBeneficiary(obj)
            .then((res) => {
                let resource = res.data;
                if(resource.status){
                    this.props.updateStateData(resource.data.cart)
                    Toaster.create({position: Position.TOP}).show({message: resource.message,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message: resource.message,intent: Intent.DANGER});
                }
                this.props.setButtonStatus(true);
                this.setState({
                    checkLoader:false
                })
            }).catch((error)=>{
                this.setState({
                    checkLoader:false
                })
                this.props.setButtonStatus(true);
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
        });
    }

    removeBeneficiary = (id) => {
        let obj = {
            beneficiary_id : id
        }
        this.setState({
            removeLoader:true
        })
        this.props.setButtonStatus(false);
        this.props.removeBeneficiary(obj)
            .then((res) => {
                let resource = res.data;
                if(resource.status){
                    this.props.updateStateData(resource.data.cart)
                    Toaster.create({position: Position.TOP}).show({message: resource.message ,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message: resource.message ,intent: Intent.DANGER});
                }
                this.setState({
                    removeLoader:false
                })
                this.props.setButtonStatus(true);
            })
            .catch((error)=>{
                this.setState({
                    removeLoader:false
                })
                this.props.setButtonStatus(true);
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            })
    }



    render() {
        return(
            <Tr className="mb_pl--none">
                <Td className="float-left">
                    {
                        !this.state.checkLoader ?
                            <Checkbox
                                checked={this.props.beneficiaryData.is_active}
                                value={this.props.beneficiaryData.id}
                                onChange={(e) => {
                                    this.setState({
                                        loader: true
                                    })
                                    this.updateCartBeneficiary(e.target.value)
                                }}/>
                        : <Loader />
                    }
                </Td>
                <Td><p>{this.props.beneficiaryData.first_name + " " + this.props.beneficiaryData.last_name}</p></Td>
                <Td><p>{this.props.beneficiaryData.gender}</p></Td>
                <Td><p>{this.props.beneficiaryData.age} Years</p></Td>
                <Td>
                    {
                        !this.state.removeLoader ?
                            <p>
                                <a onClick={() => {
                                    this.props.updateBeneficiary(this.props.beneficiaryData)
                                }}><i class="fa fa-pencil"></i></a>
                                {
                                    this.props.listCount > 1 ?
                                        <a onClick={() => {
                                            this.removeBeneficiary(this.props.beneficiaryData.id)
                                        }}><i class="fa fa-trash"></i></a>
                                    :''
                                }
                            </p>
                        : <Loader />
                    }
                </Td>
            </Tr>
        )
    }
}
import {connect} from "react-redux";
import {getggLabTestDetails, addToCart} from "../../../../actions/action";
import {getggLabTestDetailsAction} from "../../../../actions";
import ProductDetails from '../component/ProductDetails';
import { Toaster, Intent, Position } from '@blueprintjs/core';


const mapStateToProps = state =>{ //alert(1)

    return{
        ggLabTestDetails:state.homeThyro.ggLabTestDetails
    }
}

const mapDispatchToProps = dispatch =>{ //alert(2)
    return{
        getggLabTestDetails : (obj) =>{
            return getggLabTestDetails(obj)
                .then((res) => {

                    let resource = res.data;
                    if(resource.status === true) {
                        dispatch(getggLabTestDetailsAction(resource.data))
                    }else{
                        dispatch(getggLabTestDetailsAction([]))
                        Toaster.create({position: Position.TOP}).show({
                            message: resource.message,
                            intent: Intent.DANGER
                        });
                    }
                })
                .catch((error)=>{
                    if (401 !== error.response.status) {
                        Toaster.create({position: Position.TOP}).show({
                            message: "Something went wrong.",
                            intent: Intent.DANGER
                        });
                    }
                });
        },

        addToCart : (obj) =>{
            return addToCart(obj);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
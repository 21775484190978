import React from 'react';
import { Button } from '@material-ui/core';

export default function Locations() {
    return (
        <div bp="3@xl 3@lg 4@md 6@sm 12@xs">
            <div className="Location_Box">
                <h3>Thyrocare Aarogyam Center</h3>
                <ul>
                    <li><span>Shop No.3-4, Near Natural</span></li>
                    <li><span>Pin Code : 490020</span></li>
                    <li><i className="fa fa-phone"></i>&nbsp;<span>9685139112</span></li>
                </ul>
                <div bp="grid">
                    <div bp="10 11@xl 11@lg 11@md 11@sm 6@xs">
                        <Button variant="outlined" className='View_Lab_Btn'>View Lab Details</Button>
                    </div>
                    <div bp="2 1@xl 1@lg 1@md 1@sm 6@xs">
                        <Button variant="outlined" className='View_Lab_Btn'><i className="fa fa-map-marker"></i></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

import {connect} from "react-redux";
import {removeProductInCart} from "../../../../actions/action";
import CartProductDetails from '../component/CartProductDetails';
import { Toaster, Intent, Position } from '@blueprintjs/core';


const mapStateToProps = state =>{

    return{
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        removeProductInCart : (obj) =>{
            return removeProductInCart(obj)
                .catch((error) => {
                    if (error.response && error.response.status !== 401) {
                        Object.keys(error.response.data.error).forEach((key) => {
                            error.response.data.error[key].forEach((error) => {
                                Toaster.create({position: Position.TOP}).show({
                                    message: error,
                                    intent: Intent.DANGER
                                });
                            });
                        });
                    }
                });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartProductDetails)
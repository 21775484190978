import React from 'react';
import { style } from '../../../../style/thyrocare/thyro.css';
import SearchBar from "material-ui-search-bar";

function GGthyroSearch({ placeholder }) {
    return (
        <div>
            <div className="bp3-input-group">
                <SearchBar className="h_50 " />
            </div>
        </div>
    )
}

export default GGthyroSearch;
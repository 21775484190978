import React from "react";
import style from "../style/location.css"
import banner_2 from "../../../../images/location_page.svg";
import { PageTitle } from "gg-react-utilities";
import GGGthyrosearch from "../../HomePage/component/GGthyroSearch";
import ReactSearch from "../../HomePage/component/ReactSearchbar";
import Locations from "./Locations";
import Faq from "./Faq";

export default class CollectionCenter extends React.Component {
    render() {
        return (
            <React.Fragment>
            
                <div bp="grid 12">                
                    
                    <PageTitle title="Center Listing | GG Labs | Global Garner" />
                    
                    <div className="thyro_banner">
                        <img src={banner_2} alt="Banner 1" />
                    </div>

                </div>

                <div className="container">
                    <div className="ptb_30">
                        <div bp="grid padding-top padding-bottom">
                            <div bp="12 text-center">
                                <div className="Thyro_Location_Title">
                                    <h2>Search The Collection Center Near You</h2>
                                </div>
                            </div>
                        </div>

                        <div bp="grid">
                            <div bp="5 5@xl 4@lg 4@md 6@sm 6@xs">
                                <ReactSearch />
                            </div>
                            <div bp="7 7@xl 8@lg 8@md 6@sm 6@xs">
                                <GGGthyrosearch />
                            </div>
                            <div bp="12 padding-top">
                                <div bp="grid">
                                    <Locations />
                                    <Locations />
                                    <Locations />
                                    <Locations />
                                </div>
                            </div>
                        </div>

                        <Faq />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
import React, { Component } from "react";
import { ReactDOM } from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import banner_1 from '../../../../images/banner.png';
import style from '../../../../style/thyrocare/GGthyroBanner.css';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default class Banner extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className="thyro_banner">
                <Carousel
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    transitionDuration={5000}
                    pauseOnHover={false}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["superLargeDesktop", "desktop", "tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                >
                    {
                        this.props.slider && this.props.slider.length ?
                            this.props.slider.map((data,key)=>{
                                return (
                                    <div>
                                        <img src={data.slider_url} alt={data.name} />
                                    </div>
                                )
                            })
                        : <div></div>
                    }
                </Carousel>
            </div>
        )
    }
}

import { ssoInstance, autoInstance, Auth } from 'gg-react-utilities';
import Config from '../Config';

export const getggLabTestList = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/get-product',obj);
}

export const addToCart = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/add-cart',obj);
}

export const getHomeDetails = () => {
    return ssoInstance.get(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/get-home');
}

export const getAvailability = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/get-pincode-availability',obj);
}

export const addUpdateBeneficiary = (obj) => {
    let url = 'user_api/v1.0.3/';
    if(obj.type == 'Inserted'){
        url += 'save-beneficiary';
    }else{
        url += 'edit-beneficiary';
    }
    return ssoInstance.post(Config.THAYROCARE_API_URL + url,obj);
}

export const getCart = () => {
    return ssoInstance.get(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/get-cart');
}

export const removeBeneficiary = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/delete-beneficiary',obj);
}

export const getSendOtp = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/otp-confirmation',obj);
}

export const getValidateOtp = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/send-otp-confirmation',obj);
}

export const updateCartBeneficiary = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/update-status',obj);
}

export const getAppointmentSlot = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/get-time-slot',obj);
}

export const setAppointment = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/fix-appointment',obj);
}

export const setReportHardCopy = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/get-report-hardcopy',obj);
}

export const applyGgVouchers = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/add-vouchers',obj);
}

export const removeProductInCart = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/delete-cart-products',obj);
}

export const beforePayment = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/before-payment',obj);
}

export const afterPayment = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/after-payment',obj);
}

export const getOrderHistory = (param) => {
    return ssoInstance.get(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/order-history?page='+param.page);
}

export const getOrderDetails = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/order-view',obj);
}

export const getggLabTestDetails = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/get-product-details',obj);
}

export const cancelOrder = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/order-cancel',obj);
}

export const applyGgCoins = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/apply-coin',obj);
}

export const getCheckoutDetails = (obj) => {
    return ssoInstance.post(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/checkout',obj);
}

export const upiPaymentCheck = (obj) => {
    return ssoInstance.get(Config.THAYROCARE_API_URL + 'user_api/v1.0.3/upi-order-status-check/'+obj.txnId);
}
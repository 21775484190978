import React, {Component} from 'react';
import { Button } from "@material-ui/core";
import Loader from '../../../../components/Loader';
import CartProductDetails from "../containter/CartProductDetails";
import TextField from '@material-ui/core/TextField';
import {Dialog, Checkbox, RadioGroup, Radio, Toaster, Intent, Position } from "@blueprintjs/core";
import QRCode from "react-qr-code";
import moment from "moment";
import Config from "../../../../Config";

export default class CartDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            vouchers:[],
            ggVouchersList:[],
            priceDetails:[],
            pgDetails:[],
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true,
            voucherLoader:false,
            paymentLoader:false,
            usedCoins:'',
            disableCoins:false,
            paymentMethod:"1",
            upiOpen: false,
            upiURL: '',
            loading:false,
            time:"05"
        }
    }

    componentDidMount() {

        if(this.props.ggCartList){
            this.setState({
                ggVouchersList:this.props.ggCartList.active_vouchers,
                priceDetails:this.props.ggCartList.price_details,
                paymentMethod:this.props.ggCartList.is_upi
            },()=>{
                this.setActiveVoucher(this.state.ggVouchersList);
                this.updateCoins();

                let obj = {
                    upi:this.state.paymentMethod
                }
                this.checkoutAPI(obj);
            })

        }
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.isOpen !== undefined) {
            this.setState({
                isOpen: nextprops.isOpen
            });
        }

        if(nextprops.ggCartList){
            this.setState({
                ggVouchersList:nextprops.ggCartList.active_vouchers,
                priceDetails:nextprops.ggCartList.price_details,
                paymentMethod:nextprops.ggCartList.is_upi
            },()=>{
                this.setActiveVoucher(this.state.ggVouchersList);
                this.updateCoins();
            })
        }

    }

    checkoutAPI = (obj) => {
        this.props.getCheckoutDetails(obj)
            .then((res) => {

                let resource = res.data;
                if(resource.status === true) {
                    this.setState({
                        checkoutDetails : resource.data,
                        priceDetails:resource.data.cart.price_details,
                        loading: false,
                        open:false
                    })
                    let voucherList = resource.data.cart;
                    this.props.updateStateData(voucherList);
                }else{
                    this.setState({
                        loading: false,
                        open:false
                    })
                    Toaster.create({position: Position.TOP}).show({
                        message: resource.message,
                        intent: Intent.DANGER
                    });
                }
            })
            .catch((error)=>{
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            })
    }

    paymentTime = () => {
        let secTimer = setInterval(() => {

            let time = moment(this.state.time, 'mmss').subtract(1, 'seconds').format('mm:ss');
            this.setState({
                time
            })

            if(this.state.upiOpen == false){
                clearInterval(secTimer);
            }

            if(moment(time, 'mmss').format('ss') % 15 == 0){
                this.PgCheck();
            }

            if(time == '00:00'){
                clearInterval(secTimer);
                window.location.href = Config.APP_URL + "/payment/failed?msg=We are processing your payment request please check back in sometimes!&title=Payment Awaiting!";
            }

        }, 1000)

    }

    PgCheck = () => {

        let obj = {
            txnId:this.state.pgDetails.pg.txnid
        }
        this.props.upiPaymentCheck(obj)
            .then((res)=>{
                let source = res.data;
                if(source.status){
                    if(source.data.payment_status) {
                        window.location.href = Config.APP_URL + "/payment/success?msg=" + source.message + "&title=Payment Success!";
                    }
                }
            })
            .catch((error)=>{
                this.setState({
                    paymentLoader:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            })

    }
    
    setActiveVoucher = (voucherList) => {
        let activeVoucher = [];
        voucherList.map((data)=>{
            if(data.status){
                activeVoucher.push(data.id);
            }
        })
        this.setState({
            vouchers:activeVoucher
        })
    }

    handleClose = () => {
        this.setState({
            isOpen: false
        }, () => {
            if (this.props.onClose) {
                this.props.onClose()
            }
        })
    }

    handleOpen = () => {
        this.setState({
            isOpen: true
        })
    }

    onUpiCloseModal = () => {
        this.setState({ upiOpen: false,paymentLoader:false });
    };


    updateCoins = () => {
        this.state.priceDetails.map((data, key) => {
            if(data.key == 'apply_gg_coin' && data.value > 0){
                this.setState({
                    usedCoins:data.value,
                    disableCoins:true
                })
            }
        });
    }

    selectedVouchers = (e) => {
        let vouchers = this.state.vouchers;
        if(e.target.checked){
            vouchers.push(e.target.value);
        }else{
            let valueToRemove = e.target.value
            vouchers = vouchers.filter(item => item != valueToRemove)
        }
        this.setState({
            vouchers
        })
    }

    applyGgVouchers = () => {
        if(this.state.vouchers.length == 0){
            Toaster.create({position: Position.TOP}).show({message:"Select GG Voucher.",intent: Intent.DANGER});
            return false;
        }

        let obj = {
            vouchers_ids: this.state.vouchers
        }

        this.setState({
            voucherLoader:true
        })
        this.props.applyGgVouchers(obj)
            .then((res) => {
                let resource = res.data;
                if(resource.status){
                    let voucherList = resource.data.cart;
                    this.props.updateStateData(voucherList);
                    this.setActiveVoucher(voucherList.active_vouchers);
                    /*this.setState({
                        ggVouchersList:voucherList.active_vouchers,
                        priceDetails:voucherList.price_details,
                    })*/
                    this.handleClose();
                    Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.DANGER});
                }
                this.setState({
                    voucherLoader:false
                })
            })
            .catch((error) => {
                this.setState({
                    voucherLoader:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            });


    }

    clearGgVouchers = () => {
        let obj = {
            vouchers_ids: []
        }

        this.setState({
            voucherLoader:true
        })
        this.props.applyGgVouchers(obj)
            .then((res) => {
                let resource = res.data;
                if(resource.status){
                    let voucherList = resource.data.cart;
                    this.props.updateStateData(voucherList);
                    this.setActiveVoucher(voucherList.active_vouchers);
                    this.setState({
                        /*ggVouchersList:voucherList.active_vouchers,
                        priceDetails:voucherList.price_details,*/
                        vouchers:[]
                    })
                    this.handleClose();
                    Toaster.create({position: Position.TOP}).show({message:"GG Voucher Remove Successfully",intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.DANGER});
                }
                this.setState({
                    voucherLoader:false
                })
            })
            .catch((error) => {
                this.setState({
                    voucherLoader:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            });
    }

    placeOrder = () => {
        let obj = {
            platform:"web"
        }
        this.setState({
            paymentLoader:true
        })
        this.props.orderPlace(obj)
            .then((res)=>{
                let source = res;
                if(source.status) {
                    if (this.state.paymentMethod == 1){
                        this.setState({
                            pgDetails:source.data,
                            upiOpen: true,
                            upiURL:source.data.upi_url
                        },()=>{
                            this.paymentTime()
                        })
                    }
                }else{
                    Toaster.create({position: Position.TOP}).show({
                        message: source.message,
                        intent: Intent.DANGER
                    });
                }
            })
            .catch((error)=>{
                this.setState({
                    paymentLoader:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            })
    }

    applyGgCoins = () => {
        let obj = {};
        if(!this.state.disableCoins) {
            if (!(/^\-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(this.state.usedCoins))) {
                Toaster.create({position: Position.TOP}).show({message: "Invalid GG Coins",intent: Intent.DANGER});
                return false
            }
            if (this.state.usedCoins <= 0) {
                Toaster.create({position: Position.TOP}).show({message: "You can redeem more then 0 GG Coins ",intent: Intent.DANGER});
                return false
            }

            if(this.state.usedCoins > parseFloat(this.props.ggCartList.max_applicable_gg_coin)){
                Toaster.create({position: Position.TOP}).show({message:"You can redeem only "+this.props.ggCartList.max_applicable_gg_coin+" GG Coins ",intent: Intent.DANGER});
                return false;
            }

            obj = {
                apply_coin: this.state.usedCoins
            }
        }else{

            this.setState({
                usedCoins: ''
            })

            obj = {
                apply_coin: 0
            }
        }

        this.setState({
            coinsLoader: true,
            disableCoins:!this.state.disableCoins
        })

        this.props.applyGgCoins(obj)
            .then((res)=>{
                let resource = res.data;
                if(resource.status){
                    let cartList = resource.data.cart;
                    this.props.updateStateData(cartList);
                    Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.SUCCESS});
                    this.setState({
                        coinsLoader: false
                    })
                }else {
                    Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.DANGER});
                    this.setState({
                        coinsLoader: false,
                        disableCoins:!this.state.disableCoins
                    })
                }
            })
            .catch((error)=>{
                this.setState({
                    coinsLoader:false,
                    disableCoins:!this.state.disableCoins
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            })

    }

    render() {
        return (
            <div className="Minicart_Shad" bp="padding">
                <div bp="grid 12">
                    <h1 bp="margin--none">Cart Detail</h1>
                    <span className="border-buttom"><strong>Selected Products:</strong></span>
                </div>

                <div className="checkout_scroller ml-2 mr-2">
                    <ul>
                        {
                            this.props.ggCartList && this.props.ggCartList.lab ?
                                this.props.ggCartList.lab.map((data,res)=>{
                                    return(
                                        <CartProductDetails
                                            cartList={data}
                                            gg_thayro_id={this.props.ggCartList.gg_thayro_id}
                                            updateStateData = {(data) => { this.props.updateStateData(data)}} />
                                    )
                                })
                                : <Loader />
                        }
                    </ul>
                </div>
                {
                    this.props.step == 5 ?
                        <div bp="grid" className="vartical-center ml-2 mr-2">
                            <div bp={this.props.step == 5 ? "5@xl 5@lg 5@md 12@sm 12@xs" : "12"}>
                                <TextField
                                    style={{"width": "100%"}}
                                    id="standard-name"
                                    label="GG-Coins"
                                    disabled={this.state.disableCoins}
                                    value={this.state.usedCoins}
                                    onChange={(e) => {
                                        let apply_coins = e.target.value;
                                        if((/^[0-9 .\b]+$/.test(apply_coins)) || apply_coins =='') {
                                            this.setState({
                                                usedCoins: apply_coins
                                            });
                                        }

                                    }}
                                    InputProps={{
                                        endAdornment:
                                            <div style={{height:"40px"}}>
                                            {
                                                this.state.coinsLoader ?
                                                    <Loader />
                                                :
                                                    <Button
                                                        style={{"font-size": "0.8rem"}}
                                                        className="main_bg_color2 h_50"
                                                        onClick={() => {
                                                            this.applyGgCoins();
                                                        }}
                                                    >
                                                        {this.state.disableCoins ? "Remove" : "Redeem"}
                                                    </Button>
                                            }
                                            </div>
                                    }}
                                />
                                <h5 style={{color:"#727272"}}>Applicable GG Coins: {this.props.ggCartList.max_applicable_gg_coin}</h5>
                            </div>
                            <div bp={this.props.step == 5 ? "5@xl 5@lg 5@md 12@sm 12@xs" : "12"} style={{marginTop:"26px"}}>
                                <span><strong>Your GG Coins Balance {this.props.ggCartList.current_gg_coin_balance}</strong></span>
                            </div>
                        </div>
                    :''
                }
                <div bp="grid padding--none">
                    <div bp="12" className="border-buttom">
                        <span><strong>Price Detail:</strong></span>
                    </div>
                    <div bp="12" className="ml-2 mr-2">
                        <div bp="grid">
                        {
                            this.state.priceDetails && this.state.priceDetails ?
                                this.props.ggCartList.price_details.map((data, res) => {
                                    return (
                                        <React.Fragment>
                                            <div className="form-group" bp="8@xl 8@lg 8@md 8@sm 8@xs text-left">
                                                <p>{data.title}</p>
                                            </div>
                                            <div className="form-group" bp="4@xl 4@lg 4@md 4@sm 4@xs text-right">
                                                <span>{data.symbol + data.value}</span>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                : <Loader/>
                        }
                        </div>
                    </div>
                </div>
                {
                    this.props.step == 5 ?
                        <React.Fragment>
                            <div bp="grid margin-top">
                                <div bp="12" className="border-buttom">
                                    <span><strong>Payment Method:</strong></span>
                                </div>
                                <div bp="12" className="ml-2 mr2">
                                    {
                                        !this.state.loading ?
                                        <RadioGroup
                                            className='d-flex mt_10'
                                            selectedValue={this.state.paymentMethod}
                                            onChange={(e) => {
                                                this.setState({
                                                    paymentMethod: e.target.value
                                                }, () => {
                                                    let obj = {
                                                        upi: this.state.paymentMethod
                                                    }

                                                    this.setState({
                                                        loading: true
                                                    })

                                                    this.checkoutAPI(obj);
                                                })
                                            }}>
                                            <Radio style={{fontSize: "1.2rem"}} className="mr-4" label={'UPI (PG charges not applicable)'}
                                                   value={"1"}/>
                                            <Radio style={{fontSize: "1.2rem"}} label={'Debit / Credit / Net Banking / Wallet'} value={"0"}/>
                                        </RadioGroup>
                                        :
                                            <Loader />

                                    }
                                </div>
                            </div>
                            <div bp="grid margin-top text-center">
                                <div bp="3@md offset-2@md">
                                    <Button
                                        bp="padding"
                                        variant="outlined"
                                        className="width-100 h_50"
                                        onClick={() => {
                                            this.props.prevStep();
                                        }}>Back</Button>
                                </div>
                                <div bp="3@md">
                                    {
                                        this.props.toggle ?
                                            this.props.toggle :
                                            <Button small={true}
                                                    bp="padding"
                                                    variant="outlined"
                                                    className="width-100 h_50"
                                                    onClick={() => this.handleOpen()}
                                            >
                                                Apply GG Voucher
                                            </Button>
                                    }
                                    <Dialog
                                        isOpen={this.state.isOpen}
                                        title={this.props.title}
                                        icon={this.props.icon ? this.props.icon : "info-sign"}
                                        onClose={this.handleClose}
                                        {...this.props}
                                    >
                                        <div bp="grid padding">
                                            <div className="checkout_scroller_title" bp="12 margin--none">
                                                <h2>Apply GG Voucher</h2>
                                            </div>
                                            <div className="checkout_scroller2" bp="12">
                                                {
                                                    this.state.ggVouchersList && this.state.ggVouchersList.length ?
                                                        <div bp="grid margin--none">
                                                            {
                                                                this.state.ggVouchersList.map((data, key) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <div bp="10">
                                                                                <h5>₹ {data.current_balance}</h5>
                                                                                <h3>#{data.id} - valid
                                                                                    till {data.valid_till}</h3>
                                                                            </div>
                                                                            <div bp="2">
                                                                                <Checkbox
                                                                                    className="main_color"
                                                                                    checked={this.state.vouchers.find(list => list === data.id)}
                                                                                    value={data.id}
                                                                                    onChange={(e) => {
                                                                                        this.selectedVouchers(e);
                                                                                    }}/>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        <div bp="grid margin--none">
                                                            <div bp="12">
                                                                <p>You have not any voucher</p>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div bp="grid 6 margin--none text-right">
                                                {
                                                    !this.state.voucherLoader ?
                                                        <React.Fragment>
                                                            <Button
                                                                bp="padding"
                                                                variant="outlined"
                                                                className="main_bg_color2 width-100 h_50"
                                                                onClick={() => {
                                                                    this.applyGgVouchers();
                                                                }}
                                                            >Apply</Button>
                                                            <Button
                                                                bp="padding"
                                                                variant="outlined"
                                                                className="main_bg_color2 width-100 h_50"
                                                                onClick={() => {
                                                                    this.clearGgVouchers();
                                                                }}
                                                            >Cancle</Button>
                                                        </React.Fragment>
                                                        : <Loader/>
                                                }
                                            </div>
                                        </div>
                                    </Dialog>
                                    <Dialog
                                        isOpen={this.state.upiOpen}
                                        title={"UPI Payment"}
                                        icon={this.props.icon ? this.props.icon : "info-sign"}
                                        onClose={this.onUpiCloseModal}
                                        {...this.props}
                                    >
                                        <div className='ggvoucher'>
                                            <h2></h2>

                                            <div bp="grid">
                                                <div bp="12">
                                                    <center>
                                                        <QRCode
                                                            value={this.state.upiURL}
                                                            title={this.state.upiURL}
                                                            level="Q" />
                                                    </center>
                                                </div>

                                                <div bp="12 text-center">
                                                    <h4 style={{color:"red"}}>Make Payment: {this.state.time}</h4>
                                                </div>

                                            </div>

                                        </div>
                                    </Dialog>
                                </div>
                                <div bp="3@md">
                                    {
                                        this.state.paymentLoader ?
                                            <Loader/>
                                            :
                                            <Button
                                                bp="padding"
                                                variant="outlined"
                                                className="width-100 h_50"
                                                onClick={() => {
                                                    this.placeOrder()
                                                }}
                                            >Place Order</Button>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    :''
                }
            </div>
        );
    }
}
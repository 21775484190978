import React from "react";
import "../OrderHistory/style/OrderHistory.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import OrderHistory from "./container/OrderHistory";

export default class Index extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <OrderHistory {...this.props} />
        );
    }
}
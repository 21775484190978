import React from "react";
import { style } from "../../../../style/thyrocare/GGthyropricebox.css";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import { Button } from "@material-ui/core";
import Loader from "../../../../components/Loader";
import { Link } from 'react-router-dom';
import { GgUtils } from "gg-react-utilities";

export default class GGthyropricebox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loader:false
        }
    }

    addToCart = (e,code,type) => {
        e.preventDefault();

        let src = {
            "code" : code,
            "type" : type
        }

        this.setState({
            loader:true
        })
        this.props.addToCart(src)
            .then((res) => {
                let source = res.data;
                if(source.status){
                    GgUtils.cartRefresh();
                    Toaster.create({position: Position.TOP}).show({message: source.message ,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({
                        message: source.message,
                        intent: Intent.DANGER
                    });
                }
                this.setState({
                    loader:false
                })
            }).catch((error)=>{
            if (401 !== error.response.status) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Something went wrong.",
                    intent: Intent.DANGER
                });
            }
            this.setState({
                loader:false
            })
        });
    }

    render() {
        return (
            <React.Fragment>
            {
                this.props.productDetails ?
                    <Link style={{"text-decoration": "none", "color": "#212529"}}
                          to={'profile-detail/' + this.props.productDetails.code }>
                        <div className="thyro_products" bp="3@xl 4@lg 6@md 6@sm">
                            <div className="icon">
                                <i className="fa fa-flask"></i>
                            </div>
                            <div className="product_content">
                                <h4>
                                    <a href="javascript:void(0);">{this.props.productDetails.name}</a>
                                </h4>
                                <p>Included {this.props.productDetails.childs.length > 0 ? this.props.productDetails.childs.length : 1} tests</p>
                                <h5>
                                    <span>₹</span>{this.props.productDetails.rate.b2C}
                                </h5>
                            </div>
                            <div className="cart">
                                {
                                    !this.state.loader ?
                                        <Button variant="contained" size="large"
                                                className="main-color width-100 h_50"
                                                onClick={(e) => {
                                                    this.addToCart(e, this.props.productDetails.code,this.props.productDetails.type);
                                                }}>Add to Cart&nbsp;<i
                                            className="fa fa-cart-plus"></i></Button>
                                    :
                                        <Loader />
                                }
                            </div>
                        </div>
                    </Link>
                :<Loader />
            }
            </React.Fragment>
        );
    }
}
import React from "react";
import {Intent, Card, Checkbox, Elevation, Button, Toaster, Position} from "@blueprintjs/core";
import { style } from '../style/Profile_Detail.css';
import TopBar from '../../../../components/topBar';
import TestIco from '../../../../images/test-tube.png'
import Loader from "../../../../components/Loader";
import { Floating_cart, Floating_order } from '../../HomePage/component/Floating_icons';

export default class ThyroFilterComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query : new URLSearchParams(this.props.location.search ? this.props.location.search : ""),
            ggLabTestDetails:'',
            loadingList:true
        };
    }

    componentDidMount() {
        let code = this.props.match.params.code ? this.props.match.params.code : "";
        let obj ={
            product_code:code
        }
        this.setState({
            loadingList:true
        })
        this.props.getggLabTestDetails(obj);
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            ggLabTestDetails: nextProps.ggLabTestDetails,
            loadingList:false,
            loader:false
        })
    }

    addToCart = (code,type) => {
        let src = {
            "code" : code,
            "type" : type
        }

        this.setState({
            loader:true
        })
        this.props.addToCart(src)
            .then((res) => {
                let source = res.data;
                if(source.status){
                    Toaster.create({position: Position.TOP}).show({message: source.message ,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message: source.message ,intent: Intent.DANGER});
                }
                this.setState({
                    loader:false
                })
            })
            .catch((error)=>{
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
                this.setState({
                    loader:false
                })
            });

    }

    render() {
        return (
            <React.Fragment>
                <div className="container mb_30">
                    <TopBar title={ this.state.ggLabTestDetails != '' ? this.state.ggLabTestDetails.product_name + " (₹" + this.state.ggLabTestDetails.rate +")" : "Lab Test Details"} {...this.props} orderHistory="orders" />
                    {
                        !this.state.loadingList ?
                                this.state.ggLabTestDetails != '' ?
                                    <div bp="grid">
                                    <div className="Profile_Test_Img" bp="6 2@lg 4@md 4@sm 6@xs offset-4 offset-6@lg offset-5@md offset-6@sm offset-6@xs">
                                        <div className="Profile_Test-flex">
                                            <img src={TestIco} alt="Test Icon"/>
                                            <h4>{this.state.ggLabTestDetails.no_of_child} Test Included</h4>
                                        </div>
                                        <h4>₹{this.state.ggLabTestDetails.rate}</h4>
                                    </div>

                                        <div bp="12">
                                            {
                                                !this.state.loader ?
                                                    <center>
                                                        <Button
                                                            style={{"font-size": "0.8rem"}}
                                                            className="main_bg_color2 h_50"
                                                            onClick={() => {
                                                                this.addToCart(this.state.ggLabTestDetails.code,this.state.ggLabTestDetails.type);
                                                            }}>ADD TO CART&nbsp;<i
                                                            className="fa fa-cart-plus"></i></Button>

                                                    </center>
                                                :
                                                    <Loader />
                                            }
                                        </div>

                                    <div bp="12" className="Profile_Test">
                                        <h3>Below Test are Included</h3>

                                        <div bp="grid">
                                            {
                                                this.state.ggLabTestDetails.child.map((data,key) => {
                                                    return(
                                                        <div bp="6 3@lg 3@md 6@sm 6@xs">
                                                            <h4>{data.name}</h4>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>

                                </div>
                            :
                                    <div bp="12@xl 12@lg 12@md 12@sm 12@xs">
                                        <div className="Form_Shad">
                                            <center style={{"margin": "30px 0 30px 0"}}>
                                                <h1 bp="margin--none">Lab Test Details Not Found!</h1>
                                                <Button
                                                    className="main_bg_color2 h_50 mt-3"
                                                    onClick={() => {
                                                        window.location = '/';
                                                    }}>Go To Home</Button>
                                            </center>
                                        </div>
                                    </div>
                        :
                            <Loader />
                    }
                </div>
                <Floating_cart />
                <Floating_order />
            </React.Fragment>
        );
    }
}
import React, { Component } from "react";
import style from '../../../../style/thyrocare/blueprint.css';
import style1 from "../../../../style/thyrocare/thyro.css";
import style2 from '../../../../style/thyrocare/GGthyroSearchbar.css';
import { Button } from "@material-ui/core";

import Select from "react-select";
import Loader from "../../../../components/Loader";

export default class Home extends React.Component {
    constructor(props) {
        super(props); //alert(3)

        this.state = {
            LabTestList: [],
            options:[],
            code:''
        }
    }

    componentDidMount() {  //alert(5)

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            LabTestList: nextProps.LabTestList
        },()=>{
            this.setTestList();
        })
    }

    setTestList = () => {
        let options = [];
        this.state.LabTestList.map((data) => {
            options.push({
                value: data.code, label: data.name, customAbbreviation: data.type
            })
        })
        this.setState({
            options
        })
    }

    render() {

        const formatOptionLabel = ({value, label, customAbbreviation}) => (
            <div style={{display: "flex"}}>
                <div
                    style={{
                        marginRight: "10px",
                        color: "#FFF",
                        background: "#00706F",
                        padding: "5px 10px",
                        borderRadius: "5px",
                    }}
                >
                    {customAbbreviation}
                </div>

                <div
                    style={{
                        lineHeight: "28px",
                    }}
                >
                    {label}
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <div className="button_bar">
                    <form>
                        <div className="container">
                            <div bp="grid text-center">
                                <div className="search_bar" bp="8@lg offset-3@lg">
                                    {
                                        this.state.options.length ?
                                            <Select
                                                defaultValue={this.state.code}
                                                placeholder="Search for a test..."
                                                formatOptionLabel={formatOptionLabel}
                                                onChange={(data) =>{
                                                  this.props.LabTestOption(data.value)
                                                  this.setState({
                                                      code:data.value
                                                  })
                                                }}
                                                options={this.state.options}
                                            />
                                        : <Loader />
                                    }
                                </div>
                                {/* <div bp="3@lg 6@md 6@sm">
                                <Button size="small" className="main_bg_color2 width-100 h_50" >
                                    <input type="file" id="upload" hidden/>
                                    <label className="thyro_upg_btn" for="upload">Upload Prescription</label>
                                </Button>
                            </div>
                            <div bp="3@lg 6@md 6@sm">
                                <Button size="small" className="main_bg_color2 width-100 h_50">Book a Test</Button>
                            </div> */}
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}




import React, { Component } from 'react';
import { style } from "../style/checkout.css";
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getAvailability} from "../../../../actions/action";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import Loader from "../../../../components/Loader";

export default class Availability extends Component {
  constructor(props) {
    super(props);

    this.state={
      pincode:'',
      mobile:'',
      pincodeLoader:false,
      mobileLoader:false,
      isOTP:false,
      mobileOTP:'',
      changeMobile:false
    }
  }

  componentDidMount() {
    this.setState({
        pincode: this.props.pincode != null ? this.props.pincode : '',
        mobile: this.props.mobile,
        changeMobile : this.props.mobile == '' || this.props.mobile == null ? true : false
    })
  }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            pincode: nextProps.pincode != null ? nextProps.pincode : '',
            mobile: nextProps.mobile,
            changeMobile : nextProps.mobile == '' || nextProps.mobile == null ? true : false
        })
    }

    getPincodeAvailability = () => {

      if(this.state.pincode.length != 6 || (/^[0-9\b]+$/.test(this.state.pincode)) == false){
          Toaster.create({position: Position.TOP}).show({message:"Invalid Pincode.",intent: Intent.DANGER});
          return false;
      }

    this.setState({pincodeLoader:true});
    let obj = {
      Pincode : this.state.pincode
    }

    this.props.getPincodeAvailability(obj)
        .then((res) => {
          let resource = res.data;
          if(resource.status){
            this.props.setPincodeAvailability(this.state.pincode,true);
            Toaster.create({position: Position.TOP}).show({message: resource.message,intent: Intent.SUCCESS});
          }else{
            this.props.setPincodeAvailability(this.state.pincode,false);
            Toaster.create({position: Position.TOP}).show({message: resource.message,intent: Intent.DANGER});
          }
          this.setState({pincodeLoader:false});
        }).catch((error)=>{
            if (error.response && 401 !== error.response.status) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Something went wrong.",
                    intent: Intent.DANGER
                });
            }
          this.setState({pincodeLoader:false});
        });
  }

  sendOtp = () => {

      if(this.state.mobile.length != 10 || (/^[0-9\b]+$/.test(this.state.mobile)) == false){
          Toaster.create({position: Position.TOP}).show({message:"Invalid Mobile Number.",intent: Intent.DANGER});
          return false;
      }

      this.setState({mobileLoader:true});
        let obj = {
          mobile_no : this.state.mobile
        }

        this.props.sendOtp(obj)
            .then((res) => {
              let resource = res.data;
              if(resource.status){
                  this.setState({isOTP: true});
                Toaster.create({position: Position.TOP}).show({message: resource.message,intent: Intent.SUCCESS});
              }else{
                Toaster.create({position: Position.TOP}).show({message: resource.message,intent: Intent.DANGER});
              }
              this.props.setMobileAvailability(this.state.mobile,false);
              this.setState({mobileLoader:false});
            }).catch((error)=>{
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
              this.setState({mobileLoader:false});
            });
  }

  validateOtp = () => {
    if(this.state.mobileOTP.length == 0){
      Toaster.create({position: Position.TOP}).show({message:"Invalid OTP.",intent: Intent.DANGER});
      return false;
    }

    this.setState({mobileLoader:true});
    let obj = {
        otp_code : this.state.mobileOTP
    }

    this.props.validateOtp(obj)
        .then((res) => {
          let resource = res.data;
          if(resource.status){
            this.props.setMobileAvailability(this.state.mobile,true);
            this.setState({ isOTP:false, changeMobile: false });
            Toaster.create({position: Position.TOP}).show({message: resource.message,intent: Intent.SUCCESS});
          }else{
            this.props.setMobileAvailability(this.state.mobile,false);
            Toaster.create({position: Position.TOP}).show({message: resource.message,intent: Intent.DANGER});
          }
          this.setState({mobileLoader:false});
        }).catch((error)=>{
            if (error.response && 401 !== error.response.status) {
                Toaster.create({position: Position.TOP}).show({
                    message: "Something went wrong.",
                    intent: Intent.DANGER
                });
            }
            this.setState({mobileLoader:false});
        });
  }

  render() {
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <div className="Form_Shad" bp="grid padding">
            <div bp="12">
              <h1 bp="margin--none">Check Availability</h1>
            </div>
            <div className="form-group" bp="8 3@xl 3@lg 3@md 6@sm 6@xs">
                <input
                    type="text"
                    className="form-control"
                    label="Pincode"
                    placeholder="Pincode*"
                    onChange={(e)=>{
                        let pincode = e.target.value;
                        if((/^[0-9\b]+$/.test(pincode)) || pincode =='') {
                            this.setState({pincode: pincode})
                        }
                    }}
                    value={this.state.pincode}
                />
            </div>
            <div className="form-group" bp="4 3@xl 3@lg 3@md 6@sm 6@xs">
              {
                !this.state.pincodeLoader ?
                <Button
                    className="main_bg_color2 width-100 h_50"
                    onClick={() => {
                      this.getPincodeAvailability();
                    }}>Check</Button>
                : <Loader />
              }
            </div>
            <div className="form-group" bp="8 3@xl 3@lg 3@md 6@sm 6@xs">
                {
                    this.state.isOTP ?
                        <input
                            type="text"
                            className="form-control"
                            placeholder="OTP"
                            label="Set OTP"
                            onChange={(e) => {
                                this.setState({mobileOTP: e.target.value})
                            }}
                            value={this.state.mobileOTP}
                        />
                    :
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile No*"
                            label="Mobile No*"
                            disabled={!this.state.changeMobile}
                            onChange={(e) => {
                                let mobile = e.target.value;
                                if((/^[0-9\b]+$/.test(mobile)) || mobile =='') {
                                    this.setState({mobile: mobile})
                                }
                            }}
                            value={this.state.mobile}
                        />
                }
            </div>
            <div className="form-group" bp="4 3@xl 3@lg 3@md 6@sm 6@xs">
              {
                this.state.mobileLoader ?
                    <Loader />
                :
                this.state.changeMobile || this.state.isOTP ?
                    this.state.isOTP ?
                        <Button
                            className="main_bg_color2 width-100 h_50"
                            onClick={()=>{  this.validateOtp(); }}>Validate OTP</Button>
                    :
                        <Button
                          className="main_bg_color2 width-100 h_50"
                          onClick={()=>{  this.sendOtp(); }}>Get OTP</Button>
                :
                    <Button className="main_bg_color2 width-100 h_50" onClick={()=> { this.setState({changeMobile:true, mobileOTP:''}) }}>Change Number</Button>
              }
            </div>
          </div>
            <div bp="grid">
            <div bp="2@md">
              {
                this.props.AvailabilityStatus && this.state.pincodeLoader == false && this.state.mobileLoader == false ?
                    <Button
                        variant="outlined"
                        className="width-100 h_50"
                        onClick={()=>{ this.props.nextStep() }}>
                      Next
                    </Button>
                    : ''
              }
            </div>
            </div>

        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}
import React from 'react';
import {Dialog} from "@blueprintjs/core";
import {GgUtils, Error} from "gg-react-utilities";
import Button from '@material-ui/core/Button';

export default class index extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        GgUtils.cartRefresh();
    }

    render() {
        try {
            let slug = this.props.match.params.status ? this.props.match.params.status : "";

            let query = new URLSearchParams(this.props.location.search ? this.props.location.search : "");

            document.title = "Order Place";

            let title = "";
            let message = "";
            //let link = "";
            let image = "";
            let redirectLink = "";


            if (slug === "success") {

                message = query.get('msg');

                title = "Your Order is Successfully Placed";

                //link = <Link to="/voucher-history" className="pb-4">View Order History</Link>;
                image = require('../../../images/success.svg');
            }else if (slug === "failed") {
                title = "Your Order Can Not be Placed"; query.get('message');
                message = query.get('msg');
                //link = <Link to="/vouchers=" className="pb-4">Go To Home</Link>;
                image = require('../../../images/cancel.svg');
            }else{
                window.location.href = '/';
            }
            redirectLink = "/orders";



           /* setTimeout(() => {
                window.location.href = redirectLink;
            }, 5000);*/

            return (
                <React.Fragment>
                    <div className={"mh-100"}>
                        <Dialog
                            isOpen={true}
                            lazy={false}
                            className="bp3-dialog-large payment-status"
                            style={{width :"60% !important"}}
                            canOutsideClickClose={false}
                        >
                            <div className="mg-brand-payments">
                                <div className="">
                                    <div className="">
                                        <div className="">
                                            <div className="paymentWrapper">
                                                <div className="upperPaymentWrapper"></div>
                                                <div className="middlePaymentWrapper">
                                                    <div className="circleWrapper">
                                                        <div className="iconWrapper">
                                                            <img src={image} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div bp="margin-top" className="col-8 lowerPaymentWrapper m-auto pb-4">
                                                    <h3>{title}</h3>
                                                    <p>{message}</p>
                                                    <Button
                                                        bp="padding"
                                                        variant="outlined"
                                                        className="width-100 h_50"
                                                        onClick={()=>{
                                                            window.location.href = '/orders'
                                                        }}
                                                    >Go To Order</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </React.Fragment>
            )
        } catch (e) {
            return (
                <Error/>
            )
        }
    }
}

import React, {Component} from 'react';

export default class Loader extends Component{

    render(){

        return(
            <center>
                <svg x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{display:'inline-block',height:'50px',width:'50px'}}>
                    <path fill="#236AEE" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                        <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite" />
                    </path>
                </svg>
           </center>
        );

    }

}

export class DotsLoader extends Component{

    render(){

        return(
            <svg x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" style={{display: 'inlineBlock',height: '15px',width: '30px'}}>
                <circle fill={this.props.fill} stroke="none" cx="10" cy="60" r="15">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate>
                </circle>
                <circle fill={this.props.fill} stroke="none" cx="50" cy="60" r="15">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"></animate>
                </circle>
                <circle fill={this.props.fill} stroke="none" cx="90" cy="60" r="15">
                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"></animate>
                </circle>
            </svg>
        );
    }
}
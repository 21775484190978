import React, {Component} from 'react';
import Homethyro from "./container/Homethyro";

export default class Index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Homethyro/>
            </React.Fragment>
        );
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import '../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import styles from '../src/style/thyrocare/thyro.css';
import reducers from './reducers';
import App from './App';
import {Notfound,Maintance,ScrollTop,GgUtils} from 'gg-react-utilities';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import Thyrocare from './pages/GGthyrocare/HomePage/Index';
import ThyroDetail from './pages/GGthyrocare/ProductDetails/Index';
import ThyroFilter from './pages/GGthyrocare/filter/index';
import LocationPage from './pages/GGthyrocare/Location/Index';
import LocationDetail from './pages/GGthyrocare/Location/component/Location_detail';
import JoinFranchise from './pages/GGthyrocare/Franchisee/Index';
import BookCovidTest from './pages/GGthyrocare/covidtest/Index'
import GGThyro_Listing from './pages/GGthyrocare/HomePage/component/GGthyro_Listing';
import MultiStepform from './pages/GGthyrocare/CheckOut/Index';
import Payments from './pages/GGthyrocare/payment/index';
import OrderHistory from './pages/GGthyrocare/OrderHistory/Index';
import Config from  './Config';

const customMiddleWare = store => next => action => {
    next(action)
}

// Auth.logout()
const createStoreWithMiddleware = applyMiddleware(customMiddleWare)(createStore);

if (process.env.REACT_APP_ENV === 'PRODUCTION'){

    GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_URL);

}

if (process.env.REACT_APP_ENV === 'PRODUCTION'){
    GgUtils.googleAnalyticalScripts(process.env.REACT_APP_GOOGLE_AUTHENTICATE_CODE);
}

ReactDOM.render(
    <React.Fragment>

        <Provider store={createStoreWithMiddleware(reducers)}>
            <BrowserRouter>
              <App>
                  <div className="flight">
                      {
                        Config.IS_MAINTENANCE_MODE ?
                        <Switch>
                          <Route path="*" component={Maintance}/>
                        </Switch>
                        :
                        <Switch>
                          <Route exact path='/' component={Thyrocare}/>
                          <Route exact path='/profile-detail/:code' component={ThyroDetail}/>
                          {/*<Route exact path='/profile-listing' component={GGThyro_Listing}/>*/}
                          <Route exact path='/Thyrofilter' component={ThyroFilter}/>
                          {/*<Route exact path='/Location' component={LocationPage}/>*/}
                          {/*<Route exact path='/location-detail' component={LocationDetail}/>*/}
                          {/*<Route exact path='/join-franchise' component={JoinFranchise}/>*/}
                          {/*<Route exact path='/covid-test' component={BookCovidTest}/>*/}
                          <Route exact path='/Checkout' component={MultiStepform}/>
                          <Route exact path="/payment/:status" component={Payments}/>
                          <Route exact path="/orders" component={OrderHistory}/>
                          <Route path="*" component={Notfound}/>
                        </Switch>
                      }
                  </div>
                  <ScrollTop/>
                </App>
            </BrowserRouter>
        </Provider>
    </React.Fragment>,
    document.querySelector('#root'));
    unregisterServiceWorker();
export const ENV = '.com';
const config = {
    APP_URL: 'https://gglabs.globalgarner' + ENV,
    //APP_URL: 'https://thyro.globalgarner' + ENV,
    THAYROCARE_API_URL: 'https://gglabs-api.globalgarner' + ENV + '/',
    ACCOUNT_API_URL: 'https://accounts.globalgarner' + ENV + '/',
    Extension: ".com",
    IS_MAINTENANCE_MODE: false
};
export const packageConfigure = {
    APP_URL: 'https://dashboard.globalgarnaer' + ENV + '/',
    ACCOUNT_API_URL: 'https://accounts.globalgarner' + ENV + '/',
    DASHBOARD_URL: 'https://dashboard.globalgarnaer' + ENV + '/',
    //USER_PROFILE_URL:'https://dashboard.globalgarnaer.'+ENV+'/profile',
    STORE_API_URL: 'https://store-api.globalgarner' + ENV + '/v1.0.3/',
    MEGABRAND_API_URL: 'https://mega-brands-api.globalgarner' + ENV + '/user/v1.0.3/',
    APP_ENV: 'production',
    Extension: '.com'
}
export default config;

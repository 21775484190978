export const getggLabTestListAction = (sources) => {
    return {
        type: 'GG_LAB_TEST',
        sources: sources
    }
}

export const getHomeDetailsAction = (sources) => {
    return {
        type: 'GG_HOME_DETAILS',
        sources: sources
    }
}

export const getCartListAction = (sources) => {
    return {
        type: 'GG_CART_LIST',
        sources: sources
    }
}

export const getOrderHistoryAction = (sources) => {
    return {
        type: 'GG_ORDER_HISTORY_LIST',
        sources: sources
    }
}

export const getggLabTestDetailsAction = (sources) => {
    return {
        type: 'GG_LAB_TEST_DETAILS',
        sources: sources
    }
}
import React, { Component } from 'react';
import { style } from "../style/checkout.css";
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { TextareaAutosize } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Select from 'react-select';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import Loader from "../../../../components/Loader";
import moment from "moment";
import appointment from "../containter/Appointment";

export class Appointment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      slotOption:[],
      email:'',
      landmark:'',
      appointment:'',
      slot:'Select Time Slot',
      slotMasterId:'',
      appointmentId:'',
      address:'',
      slotLoader:false,
      appointmentLoader:false,
      appointmentStatus:false
    }
  }

  componentDidMount() {
      if(this.props.ggCartList.appointment){
        let date =''
        if(this.props.ggCartList.appointment && this.props.ggCartList.appointment.appointment_date){
          date = moment(this.props.ggCartList.appointment.appointment_date).format('YYYY-MM-DD');
          this.setState({
            email:this.props.ggCartList.appointment.email,
            appointment:date,
            landmark:this.props.ggCartList.appointment.landmark,
            slot:this.props.ggCartList.appointment.time_slot,
            address:this.props.ggCartList.appointment.address,
          })

        }else{
          date = moment(new Date()).format('YYYY-MM-DD');
        }
        this.setState({
          appointment:date,
          email: this.props.ggCartList.email
        })

        this.getAppointmentSlot(date);
      }
  }

  componentWillReceiveProps(nextProps, nextContext) {



  }

  appointmentData = (e) => {
      let date = e.target.value;
      if(date == ''){
        Toaster.create({position: Position.TOP}).show({message:'Appointment Date can not be empty.',intent: Intent.DANGER});
      }

      this.setState({
        appointment:date
      })

    this.getAppointmentSlot(date);
  }

  getAppointmentSlot = (date) => {

    let obj = {
      Date : date
    }

    this.setState({
      slotLoader:true,
      slotOption:[]
    })

    this.props.getAppointmentSlot(obj)
        .then((res) => {
          let resource = res.data;
          if(resource.status){

            let options = [];
            resource.data.data.lSlotDataRes.map((data) => {
              options.push({
                value: data.slot, label: data.slot, slotMasterId: data.slotMasterId, appointmentId: data.id
              })
            })

            this.setState({
              slotLoader:false,
              slotOption:options
            })


          }else{
            this.setState({
              slotLoader:false,
            })
            Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.DANGER});
          }
        })
        .catch((error) => {
          if (error.response && 401 !== error.response.status) {
            Toaster.create({position: Position.TOP}).show({
              message: "Something went wrong.",
              intent: Intent.DANGER
            });
          }
          this.setState({
            slotLoader:false
          })
        });
  }

  updateAppointment = () => {

    if(this.state.email == ''){
      Toaster.create({position: Position.TOP}).show({message:"Email can not be empty.",intent: Intent.DANGER});
      return false;
    }

    if (!(/[^@]+@[^@]+\.[^@]+/.test(String(this.state.email).toLowerCase()))) {
      Toaster.create({position: Position.TOP}).show({message:"Is not a valid email address",intent: Intent.DANGER});
      return false;
    }

    if(this.state.appointment == ''){
      Toaster.create({position: Position.TOP}).show({message:"Appointment can not be empty.",intent: Intent.DANGER});
      return false;
    }

    if(this.state.landmark == ''){
      Toaster.create({position: Position.TOP}).show({message:"landmark not be empty.",intent: Intent.DANGER});
      return false;
    }

    if(this.state.slot == '' || this.state.slot == 'Select Time Slot'){
      Toaster.create({position: Position.TOP}).show({message:"Slot can not be empty.",intent: Intent.DANGER});
      return false;
    }

    if(this.state.address == ''){
      Toaster.create({position: Position.TOP}).show({message:"Address can not be empty.",intent: Intent.DANGER});
      return false;
    }

    let obj = {
      email:this.state.email,
      appointment_date:this.state.appointment,
      landmark:this.state.landmark,
      time_slot:this.state.slot,
      appointment_slot_master_id:this.state.slotMasterId,
      appointment_id:this.state.appointmentId,
      address:this.state.address
    }

    this.setState({
      appointmentLoader:true
    })

    this.props.setAppointment(obj)
        .then((res) => {
          let resource = res.data;
          if(resource.status){

            this.setState({
              appointmentStatus:true,
              appointmentLoader:false
            })
            this.props.updateStateData(resource.data.cart);
            Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.SUCCESS});
          }else{
            this.setState({
              appointmentLoader:false,
              appointmentStatus:false
            })
            Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.DANGER});
          }
        })
        .catch((error) => {
          this.setState({
            appointmentLoader:false
          })
          if (error.response && 401 !== error.response.status) {
            Toaster.create({position: Position.TOP}).show({
              message: "Something went wrong.",
              intent: Intent.DANGER
            });
          }
        });
  }

  render() {
    return (
      <MuiThemeProvider>
          <div className="Form_Shad" bp="grid padding">
            <div bp="12">
              <h1 bp="margin-top--none margin-bottom">Appointment Details</h1>
              <h3><Radio className="main_color" checked />Home Collection</h3>
            </div>
            <div className="form-group" bp="6 6@xl 6@lg 6@md 6@sm 6@xs">
              <input
                type="text"
                className="form-control"
                label="Email"
                placeholder="Email*"
                onChange={(e)=>{ this.setState({email:e.target.value})}}
                value={this.state.email}
              />
            </div>
            <div className="form-group" bp="6 6@xl 6@lg 6@md 6@sm 6@xs">
              <input
                type="date"
                min={moment().format('YYYY-MM-DD')}
                max={moment().add(7, 'day').format('YYYY-MM-DD')}
                className="form-control"
                label="Appointment Date"
                placeholder="Appointment Date"
                onChange={(e)=>{ this.appointmentData(e); }}
                value={this.state.appointment}
              />
            </div>
            <div className="form-group" bp="6 6@xl 6@lg 6@md 6@sm 6@xs">
              <input
                type="text"
                className="form-control"
                label="Landmark"
                placeholder="Landmark*"
                onChange={(e)=>{ this.setState({landmark:e.target.value})}}
                value={this.state.landmark}
              />
            </div>
            <div className="form-group" bp="6 6@xl 6@lg 6@md 6@sm 6@xs">
              {
                !this.state.slotLoader ?
                    <Select
                        defaultValue={{ label : this.state.slot}}
                        options={this.state.slotOption}
                        onChange={(data) => {
                          this.setState({
                            slot: data.value,
                            slotMasterId:data.slotMasterId,
                            appointmentId:data.appointmentId
                          })
                        }}
                    />
                : <Loader />
              }
            </div>
            <div className="form-group" bp="12">
              <textarea
                type="text"
                className="form-control"
                label="First Name"
                placeholder="Address"
                onChange={(e)=>{ this.setState({address:e.target.value})}}
                value={this.state.address}
              />
            </div>

            <div className="form-group" bp="12">
              <div bp="grid">
                <div bp="3@md offset-10@md">
                  {
                    !this.state.appointmentLoader ?
                      <Button
                          className="main_bg_color2 width-100 h_50"
                          onClick={() => {
                            this.updateAppointment()
                          }}>{ this.props.ggCartList && this.props.ggCartList.appointment.email ? "Check Appointment" : "Submit" }
                      </Button>
                    :<Loader />
                  }
                </div>
              </div>
            </div>

          </div>
        {
          this.state.appointmentLoader == false && this.state.slotLoader == false ?
              <div bp="grid margin-top padding-bottom">
                <div bp="2@md">
                  <Button
                      bp="padding"
                      variant="outlined"
                      className="width-100 h_50"
                      onClick={() => {
                        this.props.prevStep()
                      }}>Back</Button>
                </div>
                <div bp="3@md">
                  {
                    this.state.appointmentStatus ?
                        <Button
                            bp="padding"
                            variant="outlined"
                            className="width-100 h_50"
                            onClick={() => {
                              this.props.nextStep()
                            }}
                        >Next</Button>
                        : ''
                  }
                </div>
              </div>
          :''
        }
      </MuiThemeProvider>
    );
  }
}

export default Appointment;
import React from "react";
import {Table, Thead, Tbody, Td, Tr, Th} from "react-super-responsive-table";
import TopBar from "../../../../components/topBar";
import {Button} from "@material-ui/core";
import Loader from "../../../../components/Loader";
import DotsLoader from "../../../../components/Loader";
import {AnchorButton, Classes, Code, Dialog, H5, Intent, Position, Switch, Toaster, Tooltip} from "@blueprintjs/core";

export default class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderHistory:[],
            page:1,
            stopLoadMore: false,
            loader:true,
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true,
            orderDetails:[],
            orderDetailsLoader:false,
            isDelOpen:false,
            CancelOrderObj:{}
        }
        this.handlepopupClose = this.handlepopupClose.bind(this)
    }

    handleClose=()=>{
        this.setState({
            isOpen: false
        })
    }

    orderDetails=(id)=>{
        this.setState({
            isOpen: true,
            orderDetailsLoader:true,
            orderDetails:[],
        })

        let param = {
            OrderNo : id
        }
        this.props.getOrderDetails(param)
            .then((res) => {
                let source = res.data;
                if(source.status){
                    this.setState({
                        orderDetails:source.data
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({message:source.message,intent: Intent.DANGER});
                }
                this.setState({
                    orderDetailsLoader:false
                })
            })
            .catch((error) => {
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
                this.setState({
                    orderDetailsLoader:false
                })
            });
    }

    componentDidMount() {

        this.getOrderHistory();
    }

    cancelOrder = () => {
        this.setState({
            cancelLoader:true
        })
        
        this.props.cancelOrder(this.state.CancelOrderObj)
            .then((res) => {
                let source = res.data;
                if(source.status){
                    Toaster.create({position: Position.TOP}).show({
                        message: source.message,
                        intent: Intent.SUCCESS
                    });
                    this.setState({
                        orderHistory: source.data.orders
                    })
                }else{
                    Toaster.create({position: Position.TOP}).show({
                        message: source.message,
                        intent: Intent.DANGER
                    });
                }

                this.setState({
                    cancelLoader:false
                })
            })
            .catch((error) => {
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
                this.setState({
                    cancelLoader:false
                })
            });
    }

    getOrderHistory = () => {
        this.setState({
            loader:true
        })
        let param = {
            page : this.state.page
        }
        this.props.getOrderHistory(param)
            .then((res) => {
                let source = res.data;
                if(source.status){
                    let data = source.data;
                    let oldData = this.state.orderHistory;
                    let newDataFromApi = data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState( {
                        orderHistory:newData,
                        loader:false
                    });

                    if(data.current_page == data.last_page){
                        this.setState({
                            stopLoadMore: true
                        })
                    }else{
                        this.setState({
                            stopLoadMore: false
                        })
                    }
                }
            })
            .catch((error) => {
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
                this.setState({
                    loader:false
                })
            });
    }

    NextPage= () =>{
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{

            this.getOrderHistory()
        })

    }

    handleScroll=()=>{

        var orderHistory = document.getElementById('orderHistory');
        var orderHistoryHeight = (orderHistory.scrollHeight)-(orderHistory.scrollTop+590);

        if (parseInt(orderHistoryHeight) === 0){
            if(this.state.stopLoadMore === false){
                this.NextPage()
            }
        }
    }

    handleOpen = (data) =>{
        this.setState({ isDelOpen: true, OrderId:data });

    }

    handleClose(){
        this.setState({ isDelOpen: false });
        //this.props.userActiveBill();
    }

    handlepopupClose(){
        this.setState({ isDelOpen: false });
    }

    render() {
        return (
            <div className="container">

                <div className="mt_20 mb_20">
                    <div bp="grid 12" >
                        <TopBar backUrl="/" title="Order History" {...this.props} />
                        <div id={"orderHistory"} style={{'height':'100%','maxHeight':'590px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                            <Table className="responsive_table">
                                <Thead>
                                <Tr>
                                    <Th bp="text-left">Order Id</Th>
                                    <Th bp="text-left">Receipt No.</Th>
                                    <Th bp="text-left">Order Status</Th>
                                    <Th bp="text-left">CB Type</Th>
                                    <Th bp="text-left">Amount</Th>
                                    <Th bp="text-center">Action</Th>
                                </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        this.state.orderHistory && this.state.orderHistory.length ?
                                            this.state.orderHistory.map((data,key) => {
                                                return(
                                                    <Tr className="mb1rem" keys={key}>
                                                        <Td>
                                                            <p>{data.order_id}</p>
                                                        </Td>
                                                        <Td>
                                                            <p>{data.receipt_no}</p>
                                                        </Td>
                                                        <Td>
                                                            <p>{data.order_status_text}</p>
                                                        </Td>
                                                        <Td>
                                                            <p>{data.caseback_choice_text}</p>
                                                        </Td>
                                                        <Td>
                                                            <p>₹{data.grandtotal}</p>
                                                        </Td>
                                                        <Td>
                                                            <div className="orderhistory_btn">
                                                            {
                                                                data.is_view == 1 ?
                                                                    <Button
                                                                            className="bp3-button bp3-small bp3-intent-primary btn-view"
                                                                            onClick={() => {
                                                                                this.orderDetails(data.thayro_ref_order_id);
                                                                            }}>
                                                                        <i className="fa fa-eye"></i>
                                                                    </Button>
                                                                :''
                                                            }
                                                            {
                                                                data.is_cancel == 1 ?
                                                                    <Button
                                                                            className="bp3-button bp3-small bp3-intent-danger"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    isDelOpen: true,
                                                                                    CancelOrderObj:{"OrderNo":data.receipt_no, "VisitId": data.receipt_no}
                                                                                })
                                                                            }}>
                                                                        <i className="fa fa-times-circle"></i>
                                                                    </Button>
                                                                    :''
                                                            }
                                                            </div>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                        :
                                            <td colSpan="6">
                                                <center>
                                                    <p className="pt_40 pb-5"><b>You have not any Order</b></p>
                                                </center>
                                            </td>
                                    }
                                </Tbody>
                            </Table>
                            {
                                this.state.loader ?
                                        <React.Fragment>
                                            <center>
                                                <div className="my-3">
                                                    <DotsLoader />
                                                </div>
                                            </center>
                                        </React.Fragment>
                                :''
                            }
                        </div>
                    </div>
                </div>
                <Dialog isOpen={this.state.isDelOpen}
                        onClose={() => this.handlepopupClose()}
                        title="Cancel Order"
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}>
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row mb-5">
                            <div className="col-12 text-center">
                                <b>Are you sure you want to cancel this order? </b>
                            </div>
                        </div>
                        <div className="row">
                            {
                                !this.state.cancelLoader ?
                                    <React.Fragment>
                                        <div className="col-6 text-center p-s-0">
                                            <Button variant="contained" size="large"
                                                    className="width-100 h_50"
                                                    onClick={() =>{ this.handlepopupClose() }}>
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="col-6 text-center p-s-0">
                                            <Button variant="contained" size="large"
                                                    className="main_bg_color2 width-100 h_50"
                                                    onClick={() =>{ this.cancelOrder() }}>
                                                Yes, cancel it!
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                :
                                    <div className="col-12 text-center p-s-0">
                                        <Loader />
                                    </div>

                        }
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    isOpen={this.state.isOpen}
                    bp="padding"
                    className="orderHistoryDialog"
                >
                    <div className="OrderHistory_Modal">

                        <div className="OrderHistory_Modal_Title" bp="grid">
                            <div bp="10">
                                <h3>Order Details</h3>
                            </div>
                            <div bp="2">
                                <h4><a onClick={()=>{ this.handleClose() }}><i className="fa fa-times main-color"></i></a></h4>
                            </div>
                        </div>
                        {
                            !this.state.orderDetailsLoader ?
                                this.state.orderDetails && this.state.orderDetails.price_details ?
                                    <React.Fragment>
                                    <div className="OrderHistory_Status" bp="grid">
                                        <div bp="6 4@xl 4@lg 4@md 6@sm 6@xs">
                                            <h5>Order id</h5>
                                            <p>{this.state.orderDetails.order_id}</p>
                                        </div>
                                        <div bp="6 4@xl 4@lg 4@md 6@sm 6@xs">
                                            <h5>GG-Labs Order Id</h5>
                                            <p>{this.state.orderDetails.thayro_ref_order_id}</p>
                                        </div>
                                        <div bp="6 4@xl 4@lg 4@md 6@sm 6@xs">
                                            <h5>Hard Copy</h5>
                                            <p>{this.state.orderDetails.hard_copy}</p>
                                        </div>
                                        <div bp="6 4@xl 4@lg 4@md 6@sm 6@xs">
                                            <h5>Order Status</h5>
                                            <p>{this.state.orderDetails.order_status}</p>
                                        </div>
                                        <div bp="6 4@xl 4@lg 4@md 6@sm 6@xs">
                                            <h5>Cashback Type</h5>
                                            <p>{this.state.orderDetails.caseback_choice}</p>
                                        </div>
                                        <div bp="6 4@xl 4@lg 4@md 6@sm 6@xs">
                                            <h5>UPV Status</h5>
                                            <p>{this.state.orderDetails.upv_status}</p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div bp="grid" className="OrderHistory">
                                        {
                                            this.state.orderDetails.price_details.map((data,key)=>{
                                                return(
                                                    <React.Fragment>
                                                        <div bp="6 6@xl 6@lg 6@md 12@sm 6@xs">
                                                            <h4>{data.title}</h4>
                                                        </div>

                                                        <div bp="6 6@xl 6@lg 6@md 12@sm 6@xs">
                                                            <h5>₹{data.value}</h5>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                    </div>

                                    {/*<div bp="grid" className="OrderHistory">

                                        <div bp="6 6@xl 6@lg 6@md 12@sm 6@xs">
                                            <h4>Sub Total</h4>
                                        </div>

                                        <div bp="6 6@xl 6@lg 6@md 12@sm 6@xs">
                                            <h5>₹0.00 </h5>
                                        </div>

                                        <div bp="6 6@xl 6@lg 6@md 12@sm 6@xs">
                                            <h4>Payment Charges (2.5 %)</h4>
                                        </div>

                                        <div bp="6 6@xl 6@lg 6@md 12@sm 6@xs">
                                            <h5>+ ₹0.00</h5>
                                        </div>

                                    </div>

                                    <div bp="grid" className="OrderHistory_Total">

                                        <div bp="6 6@xl 6@lg 6@md 12@sm 6@xs">
                                            <h4>Grand Total</h4>
                                        </div>

                                        <div bp="6 6@xl 6@lg 6@md 12@sm 6@xs">
                                            <h5 className="main_color">₹0.00</h5>
                                        </div>

                                    </div>*/}
                                        <div bp="grid" className="OrderHistory_Vouchercode" style={{"border": "none","background": "#fff"}}>

                                            <div bp="2@xl 2@lg 2@md 6@sm 12@xs">
                                                <h5><b>Customer Name</b></h5>
                                            </div>

                                            <div bp="3@xl 3@lg 3@md 6@sm 12@xs">
                                                <h5><b>Test Name</b></h5>
                                            </div>

                                            <div bp="2@xl 2@lg 2@md 6@sm 12@xs">
                                                <h5><b>Appointment Date</b></h5>
                                            </div>

                                            <div bp="3@xl 3@lg 3@md 6@sm 12@xs">
                                                <h5><b>Testing Address</b></h5>
                                            </div>

                                            <div bp="2@xl 2@lg 2@md 6@sm 12@xs">
                                                <h5><b>Action</b></h5>
                                            </div>

                                        </div>
                                        <hr/>
                                        {
                                            this.state.orderDetails.userdata.map((data, key) => {
                                                return(
                                                    <div bp="grid" className="OrderHistory_Vouchercode">

                                                        <div bp="2@xl 3@lg 3@md 6@sm 12@xs">
                                                            <h5>{data.customer_name}</h5>
                                                        </div>

                                                        <div bp="3@xl 3@lg 3@md 6@sm 12@xs">
                                                            <h5>{data.test_name}</h5>
                                                        </div>

                                                        <div bp="2@xl 2@lg 2@md 6@sm 12@xs">
                                                            <h5>{data.appointment}</h5>
                                                        </div>

                                                        <div bp="3@xl 3@lg 3@md 6@sm 12@xs">
                                                            <h5>{data.address}</h5>
                                                        </div>

                                                        <div bp="2@xl 2@lg 2@md 6@sm 12@xs text-center">
                                                            {
                                                                data.isDownload ?
                                                                    <a href={data.url} variant="contained" size="large" target="_blank"
                                                                       className="main_bg_color2 width-100 h_50 receipt-download">
                                                                        Download
                                                                    </a>
                                                                :
                                                                    <a href="#" variant="contained" size="large"
                                                                       onClick={()=>{
                                                                           Toaster.create({position: Position.TOP}).show({
                                                                               message: "The report is in progress. Please check after sometime.",
                                                                               intent: Intent.DANGER
                                                                           });
                                                                       }}
                                                                       className="main_bg_color2 width-100 h_50 receipt-download">
                                                                        Download
                                                                    </a>
                                                            }
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                </React.Fragment>
                                :
                                    ''
                            :
                                <Loader />
                        }

                    </div>

                </Dialog>
            </div>
        );
    }
}
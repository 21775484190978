/**
 * Created by rgi-60 on 12/30/21.
 */
let initialState = {
    ggLabTestList:[],
    ggHomeDetails:[],
    ggCartList:[],
    ggOrderHistoryList:[],
    ggLabTestDetails:''
};

const homesThyro = (state = initialState, action) => {

    switch (action.type) {

        case 'GG_LAB_TEST':
            return {...state, ggLabTestList: action.sources}
        case 'GG_LAB_TEST_DETAILS':
            return {...state, ggLabTestDetails: action.sources}
        case 'GG_HOME_DETAILS':
            return {...state, ggHomeDetails: action.sources}
        case 'GG_CART_LIST':
            return {...state, ggCartList: action.sources}
        case 'GG_ORDER_HISTORY_LIST':
            return {...state, ggOrderHistoryList: action.sources}
        default:
            return state
    }
}

export default homesThyro;

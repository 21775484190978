import React from 'react'
import PropTypes from 'prop-types'
import SearchBar from 'material-ui-search-bar';
import GGThyroFilterTable from './GGThyroFilterTable';
import Loader from "../../../../components/Loader";
// import ReactSearch from '../../HomePage/component/ReactSearchbar';

export default class GGGthyrosearch extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>

				<SearchBar
					value={this.props.searchHint}
					onChange={(value)=>{ this.props.updateSearchHint(value)}}
					onKeyDown={(e)=>{
						if(e.nativeEvent.key == "Enter"){
							this.props.getTestLab();
						}
					}}
					onRequestSearch={()=>{ this.props.getTestLab() }}
					onCancelSearch={()=>{ this.props.updateSearchHint(''); }}
					placeholder="Search for a Test..."/>
				<div className="ThyroFilter_Data">
				{
					!this.props.loadingList ?
						this.props.LabTestList ?
							this.props.LabTestList.map((data,key)=>{
								return(
									<GGThyroFilterTable
										addToCart={(code,type)=>{ this.props.addToCart(code,type); }}
										keys={key}
										testList={data}/>
								)
							})
						:''
					:<Loader />
				}
				</div>
			</React.Fragment>
		)
	}
}
import {connect} from "react-redux";
import {addToCart, getggLabTestList, getHomeDetails} from "../../../../actions/action";
import {getggLabTestListAction, getHomeDetailsAction} from "../../../../actions";
import Home from '../component/Homethyro';
import { Toaster, Intent, Position } from '@blueprintjs/core';


const mapStateToProps = state =>{

    return{
        ggLabTestList:state.homeThyro.ggLabTestList,
        ggHomeDetails:state.homeThyro.ggHomeDetails
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getggLabTestList : (obj) =>{
            return getggLabTestList(obj)
                .then((res) => {

                    let resource = res.data;

                    if(resource.status === true) {
                        dispatch(getggLabTestListAction(resource.data.data))
                    }
                })
                .catch((error)=>{
                    if (401 !== error.response.status) {
                        Toaster.create({position: Position.TOP}).show({
                            message: "Something went wrong.",
                            intent: Intent.DANGER
                        });
                    }
                });
        },
        getHomeDetails : () =>{
            return getHomeDetails()
                .then((res) => {

                    let resource = res.data;

                    if(resource.status === true) {
                        dispatch(getHomeDetailsAction(resource.data))
                    }
                })
                .catch((error)=>{                    
                    if (401 !== error.response.status) {
                        Toaster.create({position: Position.TOP}).show({
                            message: "Something went wrong.",
                            intent: Intent.DANGER
                        });
                    }
                });
        },

        addToCart : (obj) =>{
            return addToCart(obj);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
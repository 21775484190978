import React, { Component } from 'react';
import { style } from "../style/checkout.css";
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Checkbox
import {Checkbox, Intent, Position, Toaster} from "@blueprintjs/core";
import Loader from "../../../../components/Loader";

export class AdditionalServices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loader:false,
            reportHardCopy:''
        }
    }

    componentDidMount() {
        this.setState({
            reportHardCopy:this.props.ggCartList.report_hard_copy
        })
    }

    setAdditionServices = (e) => {
        let report_hard_copy = 'N';
        if(e.target.checked){
            report_hard_copy = 'Y';
        }

        let obj = {
            report_hardcopy: report_hard_copy,
            gg_thayro_id: this.props.ggCartList.gg_thayro_id
        }

        this.props.setReportHardCopy(obj)
            .then((res) => {
                let resource = res.data;
                if(resource.status){

                    this.setState({
                        loader:false,
                        reportHardCopy:resource.data.cart.report_hard_copy
                    })
                    this.props.updateStateData(resource.data.cart);
                    Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.SUCCESS});
                }else{
                    this.setState({
                        loader:false
                    })
                    Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.DANGER});
                }
            })
            .catch((error) => {
                this.setState({
                    loader:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            });
    }

    render() {

    return (
      <MuiThemeProvider>
        <div className="Form_Shad" bp="grid padding">
            <div bp="12">
              <h1 bp="margin--none">Additional Services</h1>
            </div>
            <div className="form-group" bp="12">
                {
                    !this.state.loader ?
                        <Checkbox
                            checked={this.state.reportHardCopy == 'Y' ? true : false}
                            onChange={(e) => {
                                    this.setState({
                                        loader: true
                                    })
                                    this.setAdditionServices(e)
                                }}
                            label="Hardcopy Report (₹75)"
                        />
                    :<Loader />
                }
            </div>
          </div>
          {
              this.state.loader == false ?
                  <div bp="grid margin-top">
                      <div bp="2@md">
                          <Button
                              bp="padding"
                              variant="outlined"
                              className="width-100 h_50"
                              onClick={() => {
                                  this.props.prevStep();
                              }}>Back</Button>
                      </div>
                      <div bp="3@md">
                          <Button
                              bp="padding"
                              variant="outlined"
                              className="width-100 h_50"
                              onClick={() => {
                                  this.props.nextStep();
                              }}
                          >Next</Button>
                      </div>
                  </div>
                  : ''
          }
      </MuiThemeProvider>
    );
  }
}

export default AdditionalServices;
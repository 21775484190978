import React from "react";
import blueprintjs from "@blueprintjs/core"
import style from '../../../../style/thyrocare/blueprint.css';
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';

export default class GGThyroFilterTable extends React.Component {

    constructor(props) {
        super(props);
    }

    addToCart = (e,code,type) => {
        e.preventDefault();

        this.props.addToCart(code,type);
    }

    render() {
        return (
            <Link style={{"text-decoration": "none", "color": "#212529"}}
                  to={'profile-detail/' + this.props.testList.code }>
                <div className="BG_Filter_row">
                    <div bp="grid">
                        <div bp="1 1@xl 1@lg 1@md 1@sm 1@xs text-center">
                            <h5><i className="fa fa-flask"></i></h5>
                        </div>
                        <div bp="2 2@xl 2@lg 2@md 2@sm 2@xs">
                            <h5><span className="filter_lable">{this.props.testList.type}</span></h5>
                        </div>
                        <div bp="6 6@xl 6@lg 6@md 6@sm 6@xs">
                            <h5><a>{this.props.testList.name} ({this.props.testList.childs.length > 0 ? this.props.testList.childs.length : 1} test)</a></h5>
                        </div>
                        <div bp="2 2@xl 2@lg 2@md 2@sm 2@xs text-center">
                            <h5>₹{this.props.testList.rate.b2C}</h5>
                        </div>
                        <div className="ThyroFilter_Cart_btn" bp="1 1@xl 1@lg 1@md 1@sm 1@xs text-center">
                            <Button className="ThyroFilter_Cart_btn" onClick={(e)=>{ this.addToCart(e,this.props.testList.code,this.props.testList.type)}}><i className="fa fa-cart-plus"></i></Button>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

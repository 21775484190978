import {connect} from "react-redux";
import {getOrderHistory, getOrderDetails, cancelOrder} from "../../../../actions/action";
import {getOrderHistoryAction} from "../../../../actions";
import OrderHistory from '../component/OrderHistory';
import { Toaster, Intent, Position } from '@blueprintjs/core';

const mapStateToProps = state =>{ //alert(1)

    return{
        ggOrderHistoryList: state.homeThyro.ggOrderHistoryList
    }
}

const mapDispatchToProps = dispatch =>{ //alert(2)
    return{
        getOrderHistory : (param) => {
            return getOrderHistory(param);
                /*.then((res) => {
                    let source = res.data;
                    if(source.status){
                        dispatch(getOrderHistoryAction(source.data))
                    }
                })*/
        },

        getOrderDetails : (obj) => {
            return getOrderDetails(obj);
        },

        cancelOrder : (obj) => {
            return cancelOrder(obj);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory)
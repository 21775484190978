import React, {Component} from "react";
import ThyroFilterComponent from "./container/ThyroFilter";

export default class Index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <ThyroFilterComponent {...this.props}/>
            </div>
        );
    }
}
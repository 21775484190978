import React from "react";
import Certifcate from "../../../../images/quality.svg";
import System from "../../../../images/system.svg";
import Reach from "../../../../images/reach.svg";
import Cost from "../../../../images/cost.svg";
import Speed from "../../../../images/speed.svg";
import Focus from "../../../../images/focus.svg";
import FaqAccordion from './Accordion';

export default function Faq() {
    return (
        <div>
            <div className="faq faq_shad" bp="grid vertical-center">
                <div bp="5@xl 5@lg 6@md 12@sm 12@xs">
                    <div className="faq_detail">
                        <h2>Make A Right Choice. Select Thyrocare!</h2>
                        <p>
                            Thyrocare aims to give diagnostic tests at a very affordable cost by making preventive checkup reachable to billions. Thyrocare focuses on quality service, with world-class IT infrastructure helping to improve the
                            reach in the most cost-effective manner backed with speed and focused logistics.
                        </p>
                    </div>
                </div>
                <div bp="7@xl 7@lg 6@md 12@sm 12@xs">
                    <div className="faq_detail" bp="grid">
                        <div bp="4 4@xl 4@lg 6@md 6@sm 6@xs text-center">
                            <img src={Certifcate} />
                        </div>
                        <div bp="4 4@xl 4@lg 6@md 6@sm 6@xs text-center">
                            <img src={System} />
                        </div>
                        <div bp="4 4@xl 4@lg 6@md 6@sm 6@xs text-center">
                            <img src={Reach} />
                        </div>
                        <div bp="4 4@xl 4@lg 6@md 6@sm 6@xs text-center">
                            <img src={Cost} />
                        </div>
                        <div bp="4 4@xl 4@lg 6@md 6@sm 6@xs text-center">
                            <img src={Speed} />
                        </div>
                        <div bp="4 4@xl 4@lg 6@md 6@sm 6@xs text-center">
                            <img src={Focus} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq" bp="grid vertical-center">
                <div bp="12">
                    <FaqAccordion />
                </div>
            </div>
        </div>
    );
}
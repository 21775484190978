import React, {Component} from 'react';
import Loader from '../../../../components/Loader';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import {GgUtils} from "gg-react-utilities";

export default class CartProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader:false
        }
    }

    removeProductInCart = (gg_thayro_id,product_code) => {
        let obj = {
            product_code: product_code,
            gg_thayro_id : gg_thayro_id
        }
        this.setState({
            loader:true
        })
        this.props.removeProductInCart(obj)
            .then((res) => {
                let resource = res.data;
                if(resource.status){
                    this.props.updateStateData(resource.data.cart);
                    GgUtils.cartRefresh();
                    Toaster.create({position: Position.TOP}).show({message: resource.message ,intent: Intent.SUCCESS});
                    /*if(!resource.data.cart.price_details){
                        Toaster.create({position: Position.TOP}).show({
                            message: "Your cart is empty! Please add other test.",
                            intent: Intent.DANGER
                        });
                        window.location.href = '/';
                    }*/
                }else{
                    Toaster.create({position: Position.TOP}).show({message: resource.message ,intent: Intent.DANGER});
                }
                this.setState({
                    loader:false
                })
            })
            .catch((error) => {
                this.setState({
                    loader:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            })
    }

    render() {
        return (
            <li>
                <div bp="grid">
                    <div bp="11">
                        <p>{this.props.cartList.name} ({this.props.cartList.testCount} tests) | ₹{this.props.cartList.rate}</p>
                    </div>
                    <div bp="1">
                        {
                            !this.state.loader ?
                                <a className="main_color text-right" onClick={() => {
                                    this.removeProductInCart(this.props.gg_thayro_id,this.props.cartList.code)
                                }}><i class="fa fa-window-close"></i></a>
                            :
                                <Loader />
                        }
                    </div>
                </div>
            </li>
        );
    }
}
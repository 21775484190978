import React, { Component } from "react";
import { ReactDOM } from "react";
import { style } from "../../../../style/thyrocare/GGthyrobox.css";
import GGthropricebox from "../../HomePage/container/GGthropricebox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loader from "../../../../components/Loader";

export default class GGthyrobox2 extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 4
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4
            },
            tablet: {
                breakpoint: { max: 1024, min: 767 },
                items: 3
            },
            mobile: {
                breakpoint: { max: 767, min: 0 },
                items: 2
            }
        };

        return (
            <section className="GGthyrobox_nbg">
                <div className="container">
                    <div bp="grid">
                        <div className="thyro_products_title d-flex justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center main-color" bp="6@lg 6@md">
                            <h3>{this.props.Title}</h3>
                        </div>
                        <div className="thyro_products_title d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center justify-content-center main-color" bp="6@lg 6@md">
                            <h5>
                                <a href="javascript:void(0);"
                                   onClick={()=>{
                                       this.props.LabTestOption("productType="+this.props.Type)
                                   }}>View All</a>
                            </h5>
                        </div>
                    </div>
                    <div bp="grid 12">
                        <Carousel
                            responsive={responsive}
                        >
                        {
                            this.props.HomeData.length ?
                                this.props.HomeData.map((data, key) => {
                                    return(
                                        <GGthropricebox 
                                            productDetails={data}
                                            key={key} />
                                    )
                                })
                                : <Loader />
                        }
                        </Carousel>

                    </div>
                </div>
            </section>
        );
    }
}
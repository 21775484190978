import React from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

const loadOptions = (inputValue, callback) => {
  // perform a request
  const requestResults = [
      { keyword: 'New', label: 'Shark', value: 'Shark' },
      { keyword: 'Old', label: 'Dolphin', value: 'Dolphin' },
      { keyword: 'New', label: 'Whale', value: 'Whale' },
      { keyword: 'Old', label: 'Octopus', value: 'Octopus' },
      { keyword: 'New', label: 'Crab', value: 'Crab' },
      { keyword: 'Old', label: 'Lobster', value: 'Lobster' },
    ];    

  callback(requestResults)
}

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]

const options = [
  { value: "Abe", label: "Abe", customAbbreviation: "A" },
  { value: "John", label: "John", customAbbreviation: "J" },
  { value: "Dustin", label: "Dustin", customAbbreviation: "D" }
];

const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <div style={{ display: "flex" }}>
    <div 
      style={{ 
        marginRight: "10px", 
        color: "#FFF", 
        background: "#00706F", 
        padding: "5px 10px",
        borderRadius: "5px", 
      }}
    >
      {customAbbreviation}
    </div>

    <div
      style={{ 
        lineHeight: "28px", 
      }}
    >
      {label}
    </div>
  </div>
);

function ReactSearch() {
  return (
    <div>

      {/* <Select options={options} /> */}

      <Select
        defaultValue={options[0]}
        formatOptionLabel={formatOptionLabel}
        options={options}
      />

      {/* <AsyncSelect
        placeholder="Search for a test profile"
        cacheOptions
        loadOptions={loadOptions}
        onChange={(opt, meta) => console.log(opt, meta)}
        defaultOptions
      /> */}
    </div>
  );
}

export default ReactSearch;
import {connect} from "react-redux";
import {addToCart} from "../../../../actions/action";
import GGthropricebox from '../component/GGthropricebox';


const mapStateToProps = state =>{

    return{

    }
}

const mapDispatchToProps = dispatch =>{
    return{
        addToCart : (obj) =>{
            return addToCart(obj);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GGthropricebox)
import React, {Component} from 'react';
import TopBar from "../../../../components/topBar";
import Availability from "../containter/Availability";
import Beneficiary from "../containter/Beneficiary";
import Appointment from "../containter/Appointment";
import AdditionalServices from "../containter/AdditionalServices";
import CartDetails from "../containter/Payment";
import Loader from '../../../../components/Loader';
import { Button } from "@material-ui/core";

export default class Checkout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ggCartList:[],
            loader:true,
            step: 1,
            pincode:'',
            mobile:'',
            AvailabilityStatus:false,
            pincodeAvailabilityStatus:false,
            mobileAvailabilityStatus:false,
            firstName: '',
            lastName: '',
            number: '',
            email: '',
            occupation: '',
            city: '',
            bio: ''
        };

    }

    componentDidMount() {
        this.setState({
            loader:true
        })
        this.props.getCart();
    }

    componentWillReceiveProps(nextProps) {

        /*if(!nextProps.ggCartList.price_details){
            window.location.href = '/';
        }*/
        this.setState({
            ggCartList:nextProps.ggCartList,
            pincode:nextProps.ggCartList.pincode,
            mobile:nextProps.ggCartList.mobile,
            pincodeAvailabilityStatus:nextProps.ggCartList.pincode != null ? true : false,
            mobileAvailabilityStatus:nextProps.ggCartList.mobile != null ? true : false,
            AvailabilityStatus:nextProps.ggCartList.pincode != null && nextProps.ggCartList.mobile != null ? true : false,
            loader:false
        })
    }

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    // Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };


    // Handle fields change
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    };

    setPincodeAvailability = (pincode,status) => {
        this.setState({
            pincode:pincode,
            pincodeAvailabilityStatus:status,
        },()=> {
            this.setAvailabilityStatus();
        })
    }
    setMobileAvailability = (mobile,status) => {
        this.setState({
            mobile:mobile,
            mobileAvailabilityStatus:status,
        },()=> {
            this.setAvailabilityStatus();
        })
    }

    setAvailabilityStatus = () => {
        let status = false;
        if(this.state.pincodeAvailabilityStatus && this.state.mobileAvailabilityStatus){
            status = true;
        }
        this.setState({
            AvailabilityStatus:  status
        })
    }

    updateStateData = (ggCartList) =>{
        this.setState({
            ggCartList
        })
    }
    render() {
        const { step } = this.state;
        const { firstName, lastName, email, occupation, city, bio } = this.state;
        const values = { firstName, lastName, email, occupation, city, bio };
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar backUrl="/" title="Checkout" {...this.props} />
                    <div bp="grid">
                        {
                            !this.state.loader ?
                                <React.Fragment>
                                    {
                                        !this.state.ggCartList.price_details ?
                                            <div bp="12@xl 12@lg 12@md 12@sm 12@xs">
                                            <div className="Form_Shad">
                                                <center style={{"margin": "30px 0 30px 0"}}>
                                                    <h1 bp="margin--none">Your Cart is Empty! Please add Test.</h1>
                                                    <Button
                                                        className="main_bg_color2 h_50 mt-3"
                                                        onClick={() => {
                                                            window.location = '/';
                                                        }}>Go To Home</Button>
                                                </center>
                                                </div>
                                            </div>
                                        :
                                            <React.Fragment>
                                            <div bp={ step == 5 ? "" : "8@xl 8@lg 8@md 12@sm 12@xs"}>
                                                {
                                                     step == 1 ?
                                                        <Availability
                                                            nextStep={() => {
                                                                this.nextStep();
                                                            }}
                                                            setPincodeAvailability={(pincode, status) => {
                                                                this.setPincodeAvailability(pincode, status);
                                                            }}
                                                            setMobileAvailability={(mobile, status) => {
                                                                this.setMobileAvailability(mobile, status);
                                                            }}
                                                            AvailabilityStatus={this.state.AvailabilityStatus}
                                                            pincode={this.state.pincode}
                                                            mobile={this.state.mobile}
                                                        />
                                                     : step == 2 ?
                                                        <Beneficiary
                                                            ggCartList={this.state.ggCartList}
                                                            updateStateData={(data) => {
                                                                this.updateStateData(data)
                                                            }}
                                                            nextStep={() => {
                                                                this.nextStep()
                                                            }}
                                                            prevStep={() => {
                                                                this.prevStep()
                                                            }}
                                                        />
                                                     : step == 3 ?
                                                        <Appointment
                                                            nextStep={() => {
                                                                this.nextStep()
                                                            }}
                                                            prevStep={() => {
                                                                this.prevStep()
                                                            }}
                                                            ggCartList={this.state.ggCartList}
                                                            updateStateData={(data) => {
                                                                this.updateStateData(data)
                                                            }}
                                                        />
                                                     : step == 4 ?
                                                        <AdditionalServices
                                                            ggCartList={this.state.ggCartList}
                                                            nextStep={() => {
                                                                this.nextStep()
                                                            }}
                                                            prevStep={() => {
                                                                this.prevStep()
                                                            }}
                                                            updateStateData={(data) => {
                                                                this.updateStateData(data)
                                                            }}
                                                        />
                                                     /*: step == 5 ?
                                                        <Payment
                                                            ggCartList={this.state.ggCartList}
                                                            prevStep={() => {
                                                                this.prevStep()
                                                            }}
                                                            updateStateData={(data) => {
                                                                this.updateStateData(data)
                                                            }}
                                                        />*/
                                                     :
                                                        (console.log('This is a multi-step form built with React.'))
                                                }
                                            </div>
                                            <div bp={step == 5 ? "8@xl 8@lg 8@md 12@sm 12@xs offset-3@xl offset-3@lg offset-3@md" : "4@xl 4@lg 4@md 12@sm 12@xs"}>
                                                <CartDetails
                                                    step={step}
                                                    prevStep={() => {
                                                        this.prevStep()
                                                    }}
                                                    updateStateData={(data) => {
                                                        this.updateStateData(data)
                                                    }}
                                                    ggCartList={this.state.ggCartList}/>
                                            </div>
                                        </React.Fragment>
                                   }
                                </React.Fragment>
                            :
                            <div bp="12@xl 12@lg 12@md 12@sm 12@xs">
                                <Loader/>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

import * as React from "react";
import Collapsible from "react-collapsible";

export default function FaqAccordion() {
    return (
        <div>
            <h2 className="text-center">FAQ</h2>
            <div className="accordian">
                <Collapsible trigger={
                    <div className="faq_title_Shad" bp="grid">
                        <div bp="11">
                            <h2>Is it safe to do a Blood test In Lab During COVID?</h2>
                        </div>
                        <div className="aligh_right" bp="1">
                            <i className="fa fa-arrow-down"></i>
                        </div>
                    </div>
                }>
                    <p>Yes, Thyrocare is a NABL aggregate lab. We make sure that all the safety measures are taken care of. We have certified technician who goes through proper training on keeping the hygiene and right way to do a blood test. Not only that, but we also follow all COVID guidelines while doing a Blood test or COVID RT-PCR Test.</p>
                </Collapsible>
                
                <Collapsible trigger={
                    <div className="faq_title_Shad" bp="grid">
                        <div bp="11">
                            <h2>Is Same-Day Blood Collection Possible?</h2>
                        </div>
                        <div className="aligh_right" bp="1">
                            <i className="fa fa-arrow-down"></i>
                        </div>
                    </div>
                }>
                <p>Yes, you can go on the same day for blood collection. The Walk-in centers near your location are meant to do same-day blood collection.</p>
                </Collapsible>
                
                <Collapsible trigger={
                    <div className="faq_title_Shad" bp="grid">
                        <div bp="11">
                            <h2>Can I Directly Walk-in for Blood Test in Lab Near Me?</h2>
                        </div>
                        <div className="aligh_right" bp="1">
                            <i className="fa fa-arrow-down"></i>
                        </div>
                    </div>
                }>
                <p>Yes, you can walk in at your nearest Thyrocare Aarogyam Center to get your blood collection done.</p>
                </Collapsible>
                
                <Collapsible trigger={
                    <div className="faq_title_Shad" bp="grid">
                        <div bp="11">
                            <h2>Do I have to fill in any form when I go near my blood collection walk-in center?</h2>
                        </div>
                        <div className="aligh_right" bp="1">
                            <i className="fa fa-arrow-down"></i>
                        </div>
                    </div>
                }>
                <p>Form filling is not necessary. Only a few tests require form filling, which will be guided to you by our technicians during your visit.</p>
                </Collapsible>
            </div>
        </div>
    );
}
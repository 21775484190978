import React, {Component} from 'react';
import { KeyboardArrowLeftRounded } from '@material-ui/icons';
import { PageTitle } from 'gg-react-utilities';
import { Link } from 'react-router-dom';

export default class SecondayHeader extends Component{

    componentDidMount(){
    }

    render(){

        return(
          <React.Fragment>
            <div className="pt-3 pb-2 py-4">
                <div className="row">
                    <div className="col-xl-12 col-xl-12 col-sm-12 d-flex justify-content-between">
                        <KeyboardArrowLeftRounded className='icon mt-0' onClick={()=>{
                            this.props.backUrl ?
                                window.location.href = this.props.backUrl
                            :
                                this.props.history.goBack()
                        }}/>
                        <PageTitle title={this.props.title+' | Global Garner'} />
                        <h1 className="d-inline-block navbar-title align-middle mt-0 ml-3 ml-s-1 mb-0 header-title">{this.props.title}</h1>
                        {
                            this.props.orderHistory ?
                                <h3 className="d-inline-block text-right mt-0 ml-3 ml-s-1 mb-0 navbar-title header-title">
                                <Link className="main_color" style={{"fontSize": "1.3rem"}} to={'/orders'}>Order
                                    History</Link></h3>
                            : ''
                        }
                    </div>
                </div>
            </div>
          </React.Fragment>
        );

    }

}

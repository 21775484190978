import React, {Component} from 'react';
import Checkout from './containter/Checkout';

export default class Index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Checkout {...this.props} />
    );
  }
}

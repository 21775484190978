import React from 'react';

export function Floating_cart() {
    return (
        <React.Fragment>
            <div className='container mb_30' bp="grid padding-left padding-right">
                <div className='floating-cart'>
                    <a href='/checkout'><i class="fa fa-shopping-cart"></i></a>
                </div>
            </div>
        </React.Fragment>
    );
}

export function Floating_order() {
  return (
      <React.Fragment>
          <div className='container mb_30' bp="grid padding-left padding-right">
              <div className='Floating_order'>
                  <a href='/orders'><i class="fa fa-file-text-o"></i></a>
              </div>
          </div>
      </React.Fragment>
  );
}
import React from "react";
import {Intent, Card, Checkbox, Elevation, Button, Toaster, Position} from "@blueprintjs/core";
import Drawer from "@material-ui/core/Drawer";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import style from "../style/thyrofilter.css";
// import ReactSearch from "../../../GGthyrocare/HomePage/component/GGthyroSearchbar";
import GGGthyrosearch from "./GGGthyrosearch";
import Loader from "../../../../components/Loader";
import TopBar from "../../../../components/topBar";
import {GgUtils} from "gg-react-utilities";

export default class ThyroFilterComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query : new URLSearchParams(this.props.location.search ? this.props.location.search : ""),
            LabTestList:[],
            loadingList:true,
            price: {min: 0, max: 1000},
            minPrice: 0,
            maxPrice: '',
            isFilterDrawer: false,
            searchHint:'',
            productCode: '',
            type: [],
            category:[],
            filterType: [],
            filterCat: [],
            filterFasting:[],
            fasting:[]
        };
    }

    componentDidMount() {

        if (this.state.query.has('productCode')) {

            let productCode = this.state.query.get('productCode');
            this.setState({
                productCode
            }, () => {
                this.getTestLab();
            })

        } else if (this.state.query.has('productType')) {

            let type = [this.state.query.get('productType')];
            this.setState({
                type
            }, () => {
                this.getTestLab();
            })

        } else {
            this.getTestLab();
        }
    }

    componentWillReceiveProps(nextProps) {

        if(this.state.maxPrice == ''){
            this.setState({
                price: {
                    min: 0,
                    max: nextProps.ggLabTestList.filter.rate.max
                }
            })
        }

        this.setState({
            LabTestList: nextProps.ggLabTestList.data,
            filterType: nextProps.ggLabTestList.filter.type,
            filterCat: nextProps.ggLabTestList.filter.category,
            filterFasting: nextProps.ggLabTestList.filter.fasting,
            minPrice: nextProps.ggLabTestList.filter.rate.min,
            maxPrice: nextProps.ggLabTestList.filter.rate.max,
            searchHint: this.state.productCode != '' && this.state.searchHint == '' ? nextProps.ggLabTestList.data[0].name : this.state.searchHint,
            productCode:'',
            loadingList:false
        })

    }

    handleOpen = () => {
        this.setState({
            isFilterDrawer: true,
        });
    };

    getTestLab = () => {
        let obj = {};

        if(this.state.searchHint == '' && this.state.productCode != ''){
            obj.test_code = this.state.productCode;
        }

        if(this.state.searchHint != ''){
            obj.search_hint = this.state.searchHint;
        }

        if(this.state.type.length){
            obj.type = this.state.type;
        }

        if(this.state.category.length){
            obj.category = this.state.category;
        }

        if(this.state.fasting.length){
            obj.fasting = this.state.fasting;
        }

        obj.price = this.state.price;

        this.setState({
            loadingList:true
        })

        this.props.getggLabTestList(obj);
    }

    selectedType = (e) => {
        let type = this.state.type;
        if(e.target.checked){
            type.push(e.target.value);
        }else{
            let valueToRemove = e.target.value
            type = type.filter(item => item !== valueToRemove)
        }
        this.setState({
            type
        })
    }

    selectedCat = (e) => {
        let category = this.state.category;
        if(e.target.checked){
            category.push(e.target.value);
        }else{
            let valueToRemove = e.target.value
            category = category.filter(item => item !== valueToRemove)
        }
        this.setState({
            category
        })
    }

    selectedFasting = (e) => {
        let fasting = this.state.fasting;
        if(e.target.checked){
            fasting.push(e.target.value);
        }else{
            let valueToRemove = e.target.value
            fasting = fasting.filter(item => item !== valueToRemove)
        }
        this.setState({
            fasting
        })
    }

    updateSearchHint = (searchHint) =>{
        this.setState({
            searchHint
        },()=>{
            if(searchHint == ''){
                this.getTestLab();
            }
        });
    }

    addToCart = (code,type) => {
        let src = {
            "code" : code,
            "type" : type
        }

        this.props.addToCart(src)
            .then((res) => {
                    let source = res.data;
                    if(source.status){
                        GgUtils.cartRefresh();
                        Toaster.create({position: Position.TOP}).show({message: source.message ,intent: Intent.SUCCESS});
                    }else{
                        Toaster.create({position: Position.TOP}).show({message: source.message ,intent: Intent.DANGER});
                    }
            })
            .catch((error)=>{
                if (401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong.",
                        intent: Intent.DANGER
                    });
                }
            });

    }

    render() {
        return (
            <React.Fragment>
                <div className="container">

                    <TopBar title="Lab Test" {...this.props} orderHistory="orders" />

                    <div className="row">
                        <div
                            className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 hidden-xs visible-sm visible-md visible-lg visible-xl floating-sidebar mb_40">
                            <div className="flight-sidebar">
                                <Card interactive={true} elevation={Elevation.TWO}
                                      className="border col-12 filterSearchFlightWrapper Thyro-Card">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3 className="sidebar-title">Type </h3>
                                            <hr/>
                                            <div className="slider">
                                                {
                                                    this.state.filterType.length ?
                                                        this.state.filterType.map((data, key) => {
                                                            return (
                                                                <Checkbox keys={key} 
                                                                          checked={this.state.type.find(list => list === data.title)}
                                                                          value={data.title}
                                                                          label={data.title}
                                                                          onChange={(e) => {
                                                                              this.selectedType(e)
                                                                          }}/>
                                                            )
                                                        })
                                                        : <Loader/>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <h3 className="sidebar-title mt-10">Category </h3>
                                            <hr/>
                                            <div className="slider">
                                                {
                                                    this.state.filterCat.length ?
                                                        this.state.filterCat.map((data, key) => {
                                                            return (
                                                                <Checkbox keys={key}
                                                                          checked={this.state.category.find(list => list === data.title)}
                                                                          label={data.title}
                                                                          value={data.title}
                                                                          onChange={(e) => {
                                                                                this.selectedCat(e)
                                                                            }}/>
                                                            )
                                                        })
                                                        : <Loader/>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <h3 className="sidebar-title mt-10">Sort By </h3>
                                            <hr/>
                                            <h5 className="sidebar-title">Price</h5>

                                            <div className="Thyro_price_slider">
                                                {
                                                    this.state.maxPrice != '' ?
                                                        <InputRange
                                                            maxValue={this.state.maxPrice}
                                                            minValue={0}
                                                            value={this.state.price}
                                                            onChange={(price) => {
                                                                this.setState({
                                                                    price
                                                                })
                                                            }}/>
                                                    : <Loader/>
                                                }
                                            </div>

                                            {
                                                this.state.filterFasting.length ?
                                                    this.state.filterFasting.map((data, key) => {
                                                        return (
                                                            <Checkbox keys={key}
                                                                      checked={this.state.fasting.find(list => list === data.value)}
                                                                      label={data.title}
                                                                      value={data.value}
                                                                      onChange={(e) => {
                                                                          this.selectedFasting(e)
                                                                      }}/>
                                                        )
                                                    })
                                                    : <Loader/>

                                            }
                                        </div>
                                        <div className="form-group col-md-12 ">
                                            {
                                                !this.state.loadingList ?
                                                <Button
                                                    className="main_bg_color2 float-right h_50"
                                                    onClick={() => {
                                                        this.getTestLab();
                                                    }}>Search</Button>
                                                :''
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>

                        <div
                            className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12 hidden-xs visible-sm visible-md visible-lg visible-xl floating-sidebar mb_40">
                            <div className="flight-sidebar h_576">
                                <Card interactive={true} elevation={Elevation.TWO}
                                      className="border col-12 filterSearchFlightWrapper Thyro-Card h_576">
                                    <div className="row">
                                        <div className="col-12 hidden-xs visible-sm visible-md visible-lg visible-xl">
                                            <GGGthyrosearch
                                                updateSearchHint={(value) =>{ this.updateSearchHint(value)}}
                                                addToCart={(code,type) =>{ this.addToCart(code,type)}}
                                                getTestLab={()=>{ this.getTestLab()}}
                                                searchHint={this.state.searchHint}
                                                loadingList={this.state.loadingList}
                                                LabTestList={this.state.LabTestList}/>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <div className="row flight-list mb-5">
                        <Button
                            className="floating-menu bp3-button bp3-small hidden-xl hidden-lg hidden-md hidden-sm visible-xs"
                            intent={Intent.PRIMARY} onClick={() => this.handleOpen()}>
                            <span className="bp3-icon bp3-icon-filter"></span>
                        </Button>

                        <Drawer
                            className="flight-list"
                            open={this.state.isFilterDrawer}
                            onClose={() => {
                                this.setState({
                                    isFilterDrawer: false,
                                });
                            }}
                            style={{width: "500px"}}
                        >
                            <div
                                className="col-sm-6 col-12 visible-sm visible-md visible-lg visible-xl floating-sidebar my-3 px-s-2">
                                <div className="flight-sidebar320px">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h3 className="sidebar-title">Type </h3>
                                                <hr/>
                                                <div className="slider">
                                                    {
                                                        this.state.filterType.length ?
                                                            this.state.filterType.map((data, key) => {
                                                                return (
                                                                    <Checkbox keys={key}
                                                                              checked={this.state.type.find(list => list === data.title)}
                                                                              value={data.title}
                                                                              label={data.title}
                                                                              onChange={(e) => {
                                                                                  this.selectedType(e)
                                                                              }}/>
                                                                )
                                                            })
                                                            : <Loader/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <h3 className="sidebar-title mt-10">Category </h3>
                                        <hr/>
                                        <div className="slider">
                                            {
                                                this.state.filterCat.length ?
                                                    this.state.filterCat.map((data, key) => {
                                                        return (
                                                            <Checkbox keys={key}
                                                                      checked={this.state.category.find(list => list === data.title)}
                                                                      label={data.title}
                                                                      value={data.title}
                                                                      onChange={(e) => {
                                                                          this.selectedCat(e)
                                                                      }}/>
                                                        )
                                                    })
                                                    : <Loader/>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <h3 className="sidebar-title mt-10">Sort By </h3>
                                        <hr/>
                                        <h5 className="sidebar-title">Price</h5>

                                        <div className="Thyro_price_slider">
                                            {
                                                this.state.maxPrice != '' ?
                                                    <InputRange
                                                        maxValue={this.state.maxPrice}
                                                        minValue={0}
                                                        value={this.state.price}
                                                        onChange={(price) => {
                                                            this.setState({
                                                                price
                                                            },)
                                                        }}/>
                                                : <Loader/>
                                            }
                                        </div>

                                        {
                                            this.state.filterFasting.length ?
                                                this.state.filterFasting.map((data, key) => {
                                                    return (
                                                        <Checkbox keys={key}
                                                                  checked={this.state.fasting.find(list => list === data.value)}
                                                                  label={data.title}
                                                                  value={data.value}
                                                                  onChange={(e) => {
                                                                      this.selectedFasting(e)
                                                                  }}/>
                                                    )
                                                })
                                                : <Loader/>

                                        }
                                        <div className="form-group col-md-12 ">
                                            {
                                                this.state.LabTestList.length ?
                                                    <Button
                                                        className="main_bg_color2 float-right h_50"
                                                        onClick={() => {
                                                            this.getTestLab();
                                                        }}>Search</Button>
                                                    :''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Drawer>

                        <div className="container">
                            <div
                                className="col-sm-12 col-12 hidden-sm hidden-md hidden-lg hidden-xl Thyro-Card_mb my-3 px-s-2">
                                <GGGthyrosearch
                                    updateSearchHint={(value) =>{ this.updateSearchHint(value)}}
                                    addToCart={(code,type) =>{ this.addToCart(code,type)}}
                                    getTestLab={()=>{ this.getTestLab()}}
                                    searchHint={this.state.searchHint}
                                    loadingList={this.state.loadingList}
                                    LabTestList={this.state.LabTestList}/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
import React, { Component } from "react";
import { GgUtils, PageTitle } from "gg-react-utilities";
import Banner from "../component/Banner";
import GGthyroSearchbar from "../component/GGthyroSearchbar";
import GGthyrobox1 from "../component/GGthyrobox1";
import GGthyrobox2 from "../component/GGthyrobox2";
import Config from "../../../../Config";
import Loader from "../../../../components/Loader";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import { Floating_cart, Floating_order } from './Floating_icons';

export default class Home extends React.Component {
    constructor(props){
        super(props) ; //alert(3)

        this.state = {
            LabTestList:[],
            HomeDetails:[],
            slider:[]
        }
    }

    componentDidMount(){  //alert(5)
        var obj = {
            'ProductType' : "OFFER"
        }

        this.props.getggLabTestList(obj);
        this.props.getHomeDetails();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            LabTestList: nextProps.ggLabTestList,
            HomeDetails: nextProps.ggHomeDetails,
            slider: nextProps.ggHomeDetails.sliders
        })

    }

    LabTestOption = (option) =>{
        window.location.href=Config.APP_URL+"/Thyrofilter?" + option;
    }

    LabTestDetails = (option) =>{
        window.location.href=Config.APP_URL+"/profile-detail/" + option;
    }

    render() { //alert(4)

        return (
            <React.Fragment>
                <div>
                    <div className="row">
                        <PageTitle title="Home | GG Labs | Global Garner"/>
                    </div>
                </div>
                <Banner slider={this.state.slider} />
                <GGthyroSearchbar
                    LabTestList={this.state.LabTestList}
                    LabTestOption = {(option)=>{
                        this.LabTestDetails(option);
                    }}
                />
                {
                    this.state.HomeDetails && this.state.HomeDetails.product && this.state.HomeDetails.product .length ?
                        this.state.HomeDetails.product.map((data,key) => {
                            return(
                                (key % 2) == 0 ?
                                    <GGthyrobox1
                                        key={key}
                                        LabTestOption={(option)=>{ this.LabTestOption(option) }}
                                        HomeData={data.data}
                                        Type={data.type}
                                        Title={data.name}/>
                                :
                                    <GGthyrobox2
                                        key={key}
                                        LabTestOption={(option)=>{
                                            this.LabTestOption(option)
                                        }}
                                        HomeData={data.data}
                                        Type={data.type}
                                        Title={data.name}/>
                            )
                        })
                    : <Loader />
                }
                <Floating_cart />              
                <Floating_order />              
            </React.Fragment>
        );
    }
}
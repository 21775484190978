import React, { Component } from "react";
import Select from "react-select";
import { Button } from "@material-ui/core";
import { AnchorButton, Classes, Code, Dialog, H5, Intent, Switch, Tooltip } from "@blueprintjs/core";

const options = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
];

export default class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true,
        };
    }

    handleClose = () => {
        this.setState(
            {
                isOpen: false,
            },
            () => {
                if (this.props.onClose) {
                    this.props.onClose();
                }
            }
        );
    };

    handleOpen = () => {
        this.setState({
            isOpen: true,
        });
    };

    componentWillReceiveProps(nextprops) {
        if (nextprops.isOpen !== undefined) {
            this.setState({
                isOpen: nextprops.isOpen,
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.toggle ? (
                    this.props.toggle
                ) : (
                    <Button small={true} intent="primary" className="main_bg_color2 width-100 h_50" onClick={() => this.handleOpen()}>
                        Add Beneficiary
                    </Button>
                )}
                <Dialog isOpen={this.state.isOpen} title="Beneficiary Title" className="Form_Shad" onClose={this.handleClose} {...this.props}>
                    <div className={Classes.DIALOG_BODY + "p-1 m-0"}>
                        <form>
                            <div bp="grid padding">
                                <div className="form-group" bp="4@xl 4@lg 4@md 12@sm 12@xs">
                                    <input type="text" className="form-control" placeholder="Name" />
                                </div>
                                <div className="form-group" bp="3@xl 3@lg 3@md 12@sm 12@xs">
                                    <input type="number" className="form-control" placeholder="Age" min="0" max="150" />
                                </div>
                                <div className="form-group" bp="5@xl 5@lg 5@md 12@sm 12@xs">
                                    <Select options={options} placeholder="Gender" />
                                </div>
                                <div bp="10@xl 10@lg 10@md 12@sm 12@xs"></div>
                                <div bp="2@xl 2@lg 2@md 12@sm 12@xs">
                                    <Button className="main_bg_color2 width-100 h_50">Add</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}
import React, { Component } from 'react';
import { style } from "../style/checkout.css";
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TableExample from './Table';
import Select from 'react-select';
import {Checkbox, Intent, Position, Toaster} from "@blueprintjs/core";
import Loader from "../../../../components/Loader";
import {Table, Tbody, Td, Tr} from "react-super-responsive-table";
import BeneficiaryList from "../containter/BeneficiaryList";

export default class Beneficiary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      addBeneficiary:false,
      beneficiary:[],
      BeneficiaryLabel:'',
      fName:'',
      lName:'',
      age:'',
      id:'',
      gender:'Select Gender',
      beneficiaryLoader:false,
      buttonStatus:true
    }
  }

  componentDidMount() {
    this.setState({
      beneficiary:this.props.ggCartList.beneficiary
    })
  }

  addBeneficiary = () => {

    if(this.state.fName == ''){
      Toaster.create({position: Position.TOP}).show({message:"First name can not be empty.",intent: Intent.DANGER});
      return false;
    }

    if(this.state.lName == ''){
      Toaster.create({position: Position.TOP}).show({message:"Last name can not be empty.",intent: Intent.DANGER});
      return false;
    }

    if(this.state.age == '' || this.state.age < 1){
      Toaster.create({position: Position.TOP}).show({message:"Age can not be empty or less than 1.",intent: Intent.DANGER});
      return false;
    }

    if(this.state.gender != 'M' && this.state.gender != 'F'){
      Toaster.create({position: Position.TOP}).show({message:"Gender can not be empty.",intent: Intent.DANGER});
      return false;
    }

    this.setState({
      beneficiaryLoader : true
    })
    let obj = {
      first_name:this.state.fName,
      last_name:this.state.lName,
      age:this.state.age,
      gender:this.state.gender,
      type: this.state.BeneficiaryLabel == 'Add' ? 'Inserted' : 'Update'
    }

    if(this.state.id != ''){
      obj.beneficiary_id = this.state.id;
    }

    this.props.addUpdateBeneficiary(obj)
        .then((res) => {
            let resource = res.data;
            if(resource.status){
              this.setState({
                beneficiaryLoader:false,
                addBeneficiary:false,
                fName:'',
                lName:'',
                age:'',
                gender:'Select Gender'
              })
              this.setState({
                beneficiary:resource.data.cart.beneficiary
              })
              this.props.updateStateData(resource.data.cart)
              Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.SUCCESS});
            }else{
              this.setState({
                beneficiaryLoader:false
              })
              Toaster.create({position: Position.TOP}).show({message:resource.message,intent: Intent.DANGER});
            }
        })
        .catch((error) => {
          this.setState({
            beneficiaryLoader:false
          })

          if (error.response && 401 !== error.response.status) {
            Toaster.create({position: Position.TOP}).show({
              message: "Something went wrong.",
              intent: Intent.DANGER
            });
          }
        });
  }

  updateBeneficiary = (data) =>{
    this.setState({
      id:data.id,
      fName:data.first_name,
      lName:data.last_name,
      age:data.age,
      gender:data.gender,
      addBeneficiary:true,
      BeneficiaryLabel:'Update'
    })
  }

  render() {

    return (
      <MuiThemeProvider>
          <div className="Form_Shad" bp="grid padding">
            <div bp="12">
              <h1 bp="margin-top--none margin-bottom">Add Beneficiary</h1>
              <div bp="grid">
                <div className="form-group" bp="6 text-left margin-top">
                  <h3>Book for your members</h3>
                </div>
              </div>
              <React.Fragment>
                <Table>
                  <Tbody>
                    {
                      this.state.beneficiary && this.state.beneficiary.length ?
                          this.state.beneficiary.map((data,key)=>{
                              return(
                                 <BeneficiaryList
                                     updateStateData = {(data) => {
                                       this.setState({
                                         beneficiary:data.beneficiary
                                       })
                                       this.props.updateStateData(data)
                                     }}
                                     setButtonStatus={(status)=>{
                                        this.setState({
                                          buttonStatus:status
                                        })
                                     }}
                                     updateBeneficiary = {(data) => { this.updateBeneficiary(data)}}
                                     beneficiaryData ={data}
                                     listCount={this.state.beneficiary.length}
                                     />
                              )
                          })
                      :
                          <center>
                            <p className="pt-5 pb-5"><b>You have not any Beneficiary</b></p>
                          </center>
                    }
                  </Tbody>
                </Table>
              </React.Fragment>
              {
                this.state.addBeneficiary ?
                    <div className="Form_Shad" bp="grid margin-top margin-bottom">
                      <div className="form-group" bp="6 text-left">
                        <h3>Select count of additional members*</h3>
                      </div>
                      <div className="form-group" bp="6 text-right">
                        <h3>
                          <a className="main_color" onClick={()=>{ this.setState({addBeneficiary:false})}}>
                            <i class="fa fa-times"></i>
                          </a>
                        </h3>
                      </div>
                      <div className="form-group" bp="6">
                        <input
                            type="text"
                            className="form-control"
                            label="First Name"
                            placeholder="First Name*"
                            onChange={(e)=>{ this.setState({fName:e.target.value})}}
                            value={this.state.fName}
                        />
                      </div>
                      <div className="form-group" bp="6">
                        <input
                            type="text"
                            className="form-control"
                            label="Last Name"
                            placeholder="Last Name"
                            onChange={(e)=>{ this.setState({lName:e.target.value})}}
                            value={this.state.lName}
                        />
                      </div>
                      <div className="form-group" bp="6">
                        <input
                            type="text"
                            className="form-control"
                            label="Age"
                            placeholder="Age(years)*"
                            onChange={(e)=>{
                              let age = e.target.value;
                              if((/^[0-9\b]+$/.test(age)) || age =='') {
                                this.setState({age: age})
                              }
                            }}
                            value={this.state.age}
                        />
                      </div>
                      <div className="form-group" bp="6">
                        <Select
                            defaultValue={{ label: this.state.gender}}
                            onChange={(data)=>{ this.setState({gender:data.value})}}
                            placeholder="Gender*"
                            options={[
                              {value: 'Select Gender', label: 'Select Gender' },
                              {value: 'M', label: 'Male'},
                              {value: 'F', label: 'Female'}
                            ]}
                        />
                      </div>
                      <div className="form-group" bp="12 2@xl 2@lg 2@md 6@sm 6@xs offset-6@xl">
                        {
                          !this.state.beneficiaryLoader ?
                              <Button
                                  className="main_bg_color2 width-100 h_50"
                                  onClick={() => {
                                    this.addBeneficiary();
                                  }}>{this.state.BeneficiaryLabel}</Button>
                              : <Loader/>
                        }
                      </div>
                    </div>
                    :
                    <div className="form-group" bp="grid 3@xl">
                      <Button className="main_bg_color2 width-100 h_50"
                              onClick={()=>{
                                this.setState({
                                  addBeneficiary:true,
                                  BeneficiaryLabel:'Add',
                                  id:'',
                                  fName:'',
                                  lName:'',
                                  age:'',
                                  gender:'Select Gender'
                                })}}>Add Beneficiary</Button>
                    </div>
              }
            </div>
          </div>
          {
            this.state.beneficiaryLoader == false && this.state.buttonStatus == true ?
              <div className="mb-4" bp="grid margin-top">
                  <div bp="2@md">
                    <Button
                      bp="padding"
                      variant="outlined"
                      className="width-100 h_50"
                      onClick={()=>{this.props.prevStep()}}
                    >Back</Button>
                  </div>
                  <div bp="3@md">
                    {
                      this.props.ggCartList && this.state.beneficiary.find(list => list.is_active == 1) ?
                      <Button
                          bp="padding"
                          variant="outlined"
                          className="width-100 h_50"
                          onClick={() => {
                            this.props.nextStep()
                          }}
                      >Next</Button>
                     :''
                    }
                  </div>
              </div>
            :''
          }
      </MuiThemeProvider>
    );
  }
}